import { NpContainer } from '../NPContainer'
// import Image from 'next/future/image'
import Image from 'next/image'
import { useRef, useState, useCallback } from 'react'
import MediaViewer from '../MediaViewer'

const Gallery = ({ data }) => {
  const [mediaViewerData, setMediaViewerData] = useState(null)
  const gridRefs = useRef([])

  const [parentWidth, setParentWidth] = useState(null)

  const callBackRef = useCallback((domNode) => {
    if (domNode) {
      setParentWidth(domNode.getBoundingClientRect().width)
    }
  }, [])

  const handleMediaViewer = (i) => {
    if (window.innerWidth > 480) {
      if (i === null) {
        setMediaViewerData(null)
      } else {
        const mediaViewerData = {
          index: i,
          ImageData: data.items,
        }

        setMediaViewerData(mediaViewerData)
      }
    }
  }

  const myPoster = (media) => {
    return media.url.substr(0, media.url.lastIndexOf('.')) + '.jpg'
  }

  return (
    <NpContainer
      innerProps="w-full max-w-screen-media sm:max-w-screen-mediaMd"
      outerProps="w-full items-center m-0 p-4 sm:px-8 mt-0"
    >
      {/* <div className="mx-auto flex max-w-screen-text flex-col items-center justify-center">
        <h2 className="mx-auto">{data.heading}</h2>
        {data.description && <p className="mx-auto">{data.description}</p>}
      </div> */}

      <div className={'mb-16 hidden w-full justify-center sm:flex'}>
        <button
          onClick={(e) => handleMediaViewer(1)}
          className="border-1 mt-8 border-[1px] border-solid border-dammanText py-2 px-5 font-dmsans text-md no-underline transition-all duration-200 ease-in-out hover:bg-dammanBgDark hover:text-dammanBg dark:text-dammanBgDark hover:dark:bg-white sm:mt-4  sm:py-3 md:px-6 bigScreen:text-lg"
        >
          {data.heading ? data.heading : ''}
        </button>
      </div>

      {mediaViewerData !== null && (
        <MediaViewer
          handleMediaViewer={handleMediaViewer}
          mediaViewerData={mediaViewerData}
        />
      )}

      <div
        className={`grid grid-cols-1 gap-2 sm:gap-4 md:gap-6 ${
          data.items.length === 1
            ? 'sm:grid-cols-1'
            : data.items.length === 2
            ? 'sm:grid-cols-2'
            : 'sm:grid-cols-2 md:grid-cols-3'
        } `}
      >
        {data.items.map((item, i) => {
          return (
            <div key={i}>
              <div
                ref={(ref) => (gridRefs.current[i] = ref)}
                onClick={() => handleMediaViewer(i)}
                className="relative cursor-none pb-ratio_4_3 sm:cursor-pointer sm:pb-ratio_1_1"
                style={{ height: '0' }}
              >
                <div ref={callBackRef}>
                  {parentWidth && item.mediaItem?.data !== null && (
                    <Image
                      sizes={`${parentWidth + 100}px`}
                      alt={
                        item.mediaItem?.data
                          ? item.mediaItem.data.attributes.alternativeText
                          : 'Dämmans fyr'
                      }
                      fill
                      src={
                        item.mediaItem?.data.attributes.mime !== 'video/mp4'
                          ? item.mediaItem.data.attributes.url
                          : myPoster(item.mediaItem.data.attributes)
                      }
                      style={{
                        objectFit: 'cover',
                      }}
                    />
                  )}
                </div>
                {/* <Image
                  alt={
                    item.mediaItem?.data
                      ? item.mediaItem.data.attributes.alternativeText
                      : item.videoPosterImage.data.attributes.alternativeText
                  }
                  fill
                  src={
                    item.mediaItem?.data
                      ? item.mediaItem.data.attributes.url
                      : item.videoPosterImage.data.attributes.url
                  }
                  style={{ objectFit: 'cover' }}
                /> */}

                <div className="absolute top-0 left-0 h-full w-full transition-all duration-300 ease-in-out sm:bg-black sm:bg-opacity-5 sm:hover:bg-opacity-0"></div>
              </div>
              {/* <div className="pt-4 pb-10 text-sm">{item.description}</div> */}
            </div>
          )
        })}
      </div>
    </NpContainer>
  )
}
export default Gallery
