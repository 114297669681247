import Hero from './sections/Hero'
import RichText from './sections/RichText'
import IntroMedia from './sections/IntroMedia'
import PageTeaser from './sections/PageTeaser'
import Gallery from './sections/Gallery'
import Media from './sections/Media'
import Cta from './sections/Cta'
import Map from './sections/Map'
import Button from './sections/Button'
import Contact from './sections/Contact'
import Event from './sections/Event'
// Map Strapi sections to section components
const sectionComponents = {
  ComponentSectionsHero: Hero,
  ComponentSectionsRichText: RichText,
  ComponentSectionsIntroMedia: IntroMedia,
  ComponentSectionsPageTeaser: PageTeaser,
  ComponentSectionsGallery: Gallery,
  ComponentSectionsCta: Cta,
  ComponentSectionsMap: Map,
  ComponentSectionsMedia: Media,
  ComponentSectionsButton: Button,
  ComponentSectionsContact: Contact,
  ComponentSectionsEvent: Event,
}

// Display a section individually
const Section = ({ sectionData, sectionPageData }) => {
  // Prepare the component
  const SectionComponent = sectionComponents[sectionData.__typename]

  if (!SectionComponent) {
    return null
  }

  // Display the section
  return (
    <SectionComponent data={sectionData} sectionPageData={sectionPageData} />
  )
}

const PreviewModeBanner = () => {
  const router = useRouter()
  const exitURL = `/api/exit-preview?redirect=${encodeURIComponent(
    router.asPath
  )}`

  return (
    <div className="py-4 font-semibold uppercase tracking-wide text-red-100">
      <div>
        Preview mode is on.{' '}
        <a
          className="underline"
          href={`/api/exit-preview?redirect=${router.asPath}`}
        >
          Turn off
        </a>
      </div>
    </div>
  )
}

// Display the list of sections
const Sections = ({ sections, preview, sectionPageData }) => {
  return (
    <>
      {/* Show a banner if preview mode is on */}
      {preview && <PreviewModeBanner />}
      {/* Show the actual sections */}
      {sections.map((section, i) => (
        <section
          role="contentinfo"
          key={`${section.id}-${i}`}
          className="flex flex-col items-center"
          aria-labelledby={`${section.__typename}`}
        >
          <Section sectionData={section} sectionPageData={sectionPageData} />
        </section>
      ))}
    </>
  )
}

export default Sections
