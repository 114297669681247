import { useMemo, useState, useCallback } from 'react'
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api'
import { NpContainer } from '../NPContainer'
import clsx from 'clsx'

function CurveUp({ fill }) {
  return (
    <svg version="1.1" id="Layer_1" viewBox="0 0 1920 75">
      <path
        fill={fill}
        d="M0,0c238.318,46.105,580.186,75,960,75s721.682-28.895,960-75H0z"
      />
    </svg>
  )
}

function CurveDown({ fill }) {
  return (
    <svg version="1.1" id="Layer_1" viewBox="0 0 1920 75">
      <path
        fill={fill}
        d="M1920,75.001C1681.682,28.895,1339.813,0,960,0S238.318,28.895,0,75L1920,75.001z"
      />
    </svg>
  )
}

export default function Map({ data }) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    version: 'weekly',
  })

  if (!isLoaded) return <div>Loading...</div>
  return <MyMap data={data} />
}

const mapStyles = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#444444',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#EAF0F6',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'simplified',
      },
      {
        saturation: '-65',
      },
      {
        lightness: '45',
      },
      {
        gamma: '1.78',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        saturation: '-33',
      },
      {
        lightness: '22',
      },
      {
        gamma: '2.08',
      },
    ],
  },
  {
    featureType: 'transit.station.airport',
    elementType: 'geometry',
    stylers: [
      {
        gamma: '2.08',
      },
      {
        hue: '#ffa200',
      },
    ],
  },
  {
    featureType: 'transit.station.airport',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.station.rail',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.station.rail',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'simplified',
      },
      {
        saturation: '-55',
      },
      {
        lightness: '-2',
      },
      {
        gamma: '1.88',
      },
      {
        hue: '#ffab00',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#3F5367',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
]

function MyMap({ data }) {
  const { className, longitud, latitud } = data

  const center = useMemo(
    () => ({ lat: latitud, lng: longitud }),
    [latitud, longitud]
  )

  const containerStyle = {
    width: '100%',
    height: '100%',
  }

  // mx-0 px-4 sm:px-8 mt-20 wmb-20d

  return (
    <div className={` mb-36 mt-24 w-full px-6 sm:px-8 sm:pt-0`}>
      {/* <div className={`w-full ${test2}`}> */}
      <div className="relative mx-auto w-full max-w-screen-media   ">
        <div className={`h-full w-full pb-ratio_4_3 xs:pb-ratio_16_9 `}>
          <div className="absolute top-0 bottom-0 right-0 left-0">
            <GoogleMap
              zoom={3}
              center={center}
              mapContainerStyle={containerStyle}
              options={{
                styles: mapStyles,
                // disableDefaultUwI: true,
                scrollwheel: false,
                zoomControl: true,
                // zoomControlOptions: {
                //   position: google.maps.ControlPosition.RIGHT_CENTER,
                // },
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                keyboardshortcutsControl: false,
              }}
            >
              <MarkerF position={center} />
            </GoogleMap>
          </div>

          {/* <div className="absolute bottom-0 left-0 z-[0] w-full fill-dammanBg">
                <CurveDown />
              </div> */}
        </div>
      </div>
    </div>
  )
}
