import delve from 'dlv'
import Head from 'next/head'

const Seo = ({ seo }) => {
  // const metaTags = delve(seo, 'metas');
  const metaTitle = delve(seo, 'metaTitle')
  const metaRobots = delve(seo, 'metaRobots')
  const metaImage = delve(seo, 'metaImage.data.attributes.url')
  // const structuredData = delve(seo, 'structuredData');
  const canonicalURL = delve(seo, 'canonicalURL')
  const metaDescription = delve(seo, 'metaDescription')

  return (
    <Head>
      <title>{metaTitle}</title>
      <meta name="viewport" content="width=device-width" />
      <meta name="description" content={metaDescription} key="description" />
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter:card"
      />
      <meta property="og:url" content={metaImage} key="og:url" />
      <meta property="og:title" content={metaTitle} key="og:title" />
      <meta
        property="og:description"
        content={metaDescription}
        key="og:description"
      />
      <meta
        property="og:image"
        content={delve(metaImage, 'url')}
        key="og:image"
      />
      {/* content="minimum-scale=1, initial-scale=1, width=device-width" */}
      <meta property="og:type" content="website" />
      <link rel={canonicalURL} href={'https://www.dammansfyr.se'} />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="57x57"
        href="favicon/apple-touch-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="114x114"
        href="favicon/apple-touch-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="72x72"
        href="favicon/apple-touch-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="144x144"
        href="favicon/apple-touch-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="60x60"
        href="favicon/apple-touch-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="120x120"
        href="favicon/apple-touch-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="76x76"
        href="favicon/apple-touch-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="152x152"
        href="favicon/apple-touch-icon-152x152.png"
      />
      <link
        rel="icon"
        type="image/png"
        href="favicon/favicon-196x196.png"
        sizes="196x196"
      />
      <link
        rel="icon"
        type="image/png"
        href="favicon/favicon-96x96.png"
        sizes="96x96"
      />
      <link
        rel="icon"
        type="image/png"
        href="favicon/favicon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href="favicon/favicon-16x16.png"
        sizes="16x16"
      />
      <link
        rel="icon"
        type="image/png"
        href="favicon/favicon-128.png"
        sizes="128x128"
      />
      <meta name="application-name" content="&nbsp;" />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-TileImage" content="mstile-144x144.png" />
      <meta name="msapplication-square70x70logo" content="mstile-70x70.png" />
      <meta
        name="msapplication-square150x150logo"
        content="mstile-150x150.png"
      />
      <meta name="msapplication-wide310x150logo" content="mstile-310x150.png" />
      <meta
        name="msapplication-square310x310logo"
        content="mstile-310x310.png"
      />
      {/* {metaTags &&
        metaTags.map((meta) => (
          <meta name={delve(meta, 'name')} content={delve(meta, 'content')} />
        ))} */}
      <meta name="robots" content={metaRobots} />
      <meta name="googlebot" content={metaRobots} />
      {/* <script type="application/ld+json">{structuredData}</script> */}
    </Head>
  )
}

export default Seo
