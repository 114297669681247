import React from 'react'
import ReactPlayer from 'react-player/lazy'

export default function MediaPlayer({ item }) {
  const isVideo =
    item.youtubeUrl == null ||
    item.youtubeUrl.length < 1 ||
    item.youtubeUrl == undefined

  const poster = isVideo
    ? item.mediaItem.data.attributes.url.substr(
        0,
        item.mediaItem.data.attributes.url.lastIndexOf('.')
      ) + '.jpg'
    : ''
  return (
    <>
      {!isVideo ? (
        <ReactPlayer
          url={`https://www.youtube.com/watch?v=${item.youtubeUrl.videoData}-U`}
          // url={`https://www.youtube.com/watch?v=${item.youtubeUrl.videoData}-U`}
          config={{
            youtube: {
              playerVars: { showinfo: 0, controls: 0, autoplay: 0 },
            },
          }}
          muted
          height="100%"
          width="100%"
        />
      ) : (
        <video
          controls={false}
          autoPlay={true}
          muted
          poster={poster}
          className="fluidVideoBackground"
        >
          <source
            src={item.mediaItem.data.attributes.url}
            type={item.mediaItem.data.attributes.mime}
          />
        </video>
      )}
    </>
  )
}
