export default function LogoDamman(props) {
  return (
    <svg viewBox="0 0 157.31 90.26" aria-hidden="true" {...props}>
      <path
        d="M104.8,63.36c0.75-0.7,1.47-1.54,1.81-2.51c0.85-2.05-0.18-4.32-1.83-5.65c-4.92-4.25-11.72-4.85-17.76-6.51
          c-0.07-0.24,0.02-0.26,0.02-0.52c0-0.26-0.01-0.26-0.01-0.51c0.04-0.85,0.01-1.68,0.01-2.55c-0.01-0.54-0.06-0.99,0.02-1.53
          c-0.02-0.38-0.02-0.64,0-1.02c-0.15-0.7-0.51-0.78-0.94-0.99c-0.24-0.14-0.22-0.17-0.46-0.3c-0.38-0.17-0.62-0.3-0.97-0.53
          c-0.36-0.2-0.61-0.33-0.97-0.53c-0.55-0.31-0.48-0.17-0.48-0.74c0-0.24,0-0.24,0-0.48c0.02-0.52-0.04-0.93-0.01-1.45
          c-0.01-0.38-0.04-0.67,0.03-1.03c-0.46-2.38-1.87-4.98-4.41-5.57c0.19-0.18,0.22-0.13,0.45-0.24c0.36-0.17,0.59-0.29,0.93-0.48
          c0.66-0.25,1.23-0.61,1.89-0.89c0.38-0.23,1.07-0.45,1.29-0.79c0.29-0.43,0.03-1.03-0.41-1.24c-0.25-0.1-0.26-0.08-0.51-0.17
          c-1-0.44-2-0.83-3.02-1.22c-0.39-0.13-0.64-0.22-1.01-0.39c-0.32-0.1-0.55-0.27-0.91-0.22c-0.75,0.05-0.82,0.72-0.72,1.3
          c-0.04,0.37-0.05,0.61-0.02,0.99c-0.02,0.37-0.03,0.62-0.02,0.99c-0.02,0.38-0.01,0.63,0.03,1c0,0.25-0.03,0.25-0.03,0.5
          c0,0.3-0.06,0.5,0.08,0.78c-1.43,0.17-2.6,1.08-3.44,2.21c-0.71,1.03-1.22,2.21-1.36,3.45c-0.03,0.43-0.06,0.58-0.03,1.03
          C72,38.04,72.04,38.48,72,38.98c0,0.24,0.05,0.24,0.05,0.49c0,0.24,0.08,0.26-0.05,0.47c-0.15,0.23-0.21,0.16-0.45,0.29
          c-0.37,0.18-0.62,0.29-0.98,0.5c-0.35,0.21-0.59,0.36-0.95,0.56c-0.35,0.19-1.07,0.53-1.3,0.84c-0.19,0.34-0.1,0.55-0.11,0.95
          c0.01,1.8,0,3.85,0.01,5.63c-0.33,0.09-0.66,0.18-0.98,0.27c-3.82,0.99-7.67,1.79-11.35,3.23c-1.62,0.68-3.22,1.41-4.63,2.49
          c-1.04,0.96-2.17,1.96-2.68,3.3c-0.49,1.65,0.7,3.05,2.01,3.92c1.8,1.4,4,2.1,6.18,2.67c3.71,0.89,7.54,1.35,11.35,1.53
          c3.06,0.15,6.16,0.19,9.2-0.25c1.91-0.42,3.69-1.27,5.53-1.9c2.71-0.92,5.37-1.99,8.08-2.91c0.19-0.12,0.44-0.17,0.56-0.36
          c0.2-0.31,0.11-0.66,0.14-1.01c-0.05-0.79,0.07-0.76-0.03-1.53c0.03-0.53,0.02-1,0.05-1.53c0-0.7-0.06-1.35,0-2.04
          c-0.03-0.38-0.05-0.63-0.04-1.03c0-0.26-0.08-0.27,0.01-0.51c2.81,0.54,5.72,1.17,8.04,2.93c1.11,0.86,2.28,2.12,2.11,3.61
          c-0.15,1.49-1.37,2.5-2.38,3.46c-1.76,1.36-3.64,2.6-5.7,3.47c-0.62,0.3-1.25,0.57-1.9,0.8c-2.73,1.1-5.62,1.66-8.51,2.14
          c-1.56,0.19-3.12,0.43-4.69,0.53c-5.05,0.43-10.14,0.4-15.18-0.09c-0.96-0.08-2.35-0.06-3.05,0.66c-0.88,1.06,0.88,1.57,1.59,1.65
          c5.14,0.5,10.27,0.55,15.42,0.28c2.24-0.23,4.51-0.35,6.75-0.76c3.98-0.66,7.86-1.72,11.65-3.09C99,67.28,102.26,65.79,104.8,63.36
          z M78.49,27.39c0.2-0.04,0.77,0.25,1.05,0.35c1.02,0.23,1.62,0.42,0.22,0.84c-0.31,0.16-0.53,0.28-0.84,0.43
          c-0.21,0.11-0.23,0.33-0.44,0.2C78.35,29.15,78.42,27.42,78.49,27.39z M74.69,34.13c1.78-2.57,5.03-2.1,6.27,0.69
          c-1.88-1.34-4.54-1.5-6.5-0.25C74.53,34.43,74.59,34.28,74.69,34.13z M72.19,64.05c-4.09-0.26-8.21-0.49-12.15-1.73
          c-1.42-0.49-2.85-1.03-4.16-1.79c-0.88-0.45-1.79-1.11-2.06-2.07c-0.08-0.84,0.65-1.51,1.32-1.93c0.82-0.63,1.71-1.17,2.68-1.55
          c1.9-0.85,3.92-1.4,5.94-1.88c0.09,0.24-0.04,0.26-0.04,0.51c0,0.25,0.03,0.25,0.03,0.51c0,0.25-0.04,0.25-0.04,0.51
          c0.04,0.4,0.04,0.61,0.01,1.01c0,0.53,0,1.03,0.05,1.52c-0.03,0.37-0.05,0.62-0.04,1.01c-0.01,0.38,0,0.64,0.02,1.01
          c0.02,0.45-0.1,1.11,0.13,1.5c0.21,0.33,0.55,0.38,0.89,0.5c0.38,0.14,0.63,0.21,1.01,0.33c0.48,0.26,0.95,0.39,1.48,0.56
          c0.54,0.16,0.95,0.38,1.49,0.54c0.67,0.29,1.3,0.52,2,0.71c0.37,0.14,0.62,0.26,0.97,0.42c0.37,0.14,0.65,0.17,1,0.35
          C72.55,64.08,72.37,64.06,72.19,64.05z M76.84,63.61c-0.16,0.3-0.31,0.03-0.53-0.05c-0.25-0.09-0.26-0.04-0.51-0.13
          c-0.76-0.38-0.7-0.22-1.46-0.59c-0.38-0.1-0.63-0.18-0.99-0.35c-0.51-0.2-1.01-0.36-1.5-0.49c-0.96-0.44-1.96-0.73-2.96-1.09
          c-0.52-0.2-0.93-0.41-1.47-0.57c-0.59-0.24-1.18-0.32-1.76-0.6c-0.34-0.21-0.23-0.3-0.23-0.64c-0.02-0.4-0.01-0.66,0.01-1.05
          c-0.03-0.89,0.06-1.75-0.04-2.63c0-0.4,0.03-0.66,0.06-1.05c-0.02-0.56,0-1.04-0.06-1.58c0.09-0.61-0.12-1.1-0.02-1.46
          c0.12-0.27,0.31-0.18,0.54-0.24c0.38-0.07,0.63-0.12,1-0.25c0.68-0.2,1.32-0.38,2-0.52c0.36-0.12,0.61-0.2,0.99-0.29
          c1.19-0.27,2.31-0.62,3.5-0.91c0.36-0.13,0.61-0.2,1-0.27c0.49-0.19,0.93-0.26,1.45-0.37c0.24-0.06,0.23-0.09,0.47-0.15
          c0.3-0.08,0.41-0.28,0.53,0.07c0.08,0.22-0.08,0.55-0.08,0.78c0,0.26,0.01,0.26,0.01,0.51c0.03,0.38,0.03,0.64,0.01,1.03
          c0,0.26,0.04,0.26,0.04,0.51c-0.08,1.24,0,1.04-0.01,2.08c-0.04,0.55,0.04,1.01,0,1.57c-0.06,0.71-0.02,1.39,0.02,2.09
          c-0.03,0.39-0.03,0.65,0,1.04c-0.02,0.39-0.03,0.65-0.01,1.05c-0.04,0.88-0.01,1.73-0.01,2.62c0.01,0.39,0.02,0.66,0.03,1.05
          C76.83,62.96,76.96,63.38,76.84,63.61z M76.8,40.26c-0.01,0.55-0.03,1.02,0.02,1.54c-0.03,0.39-0.03,0.65,0.01,1.03
          c-0.02,0.55-0.07,1-0.02,1.55c-0.01,0.54-0.03,1-0.04,1.55c0,0.53,0.06,0.52-0.46,0.66c-0.38,0.07-0.63,0.15-0.98,0.28
          c-1.2,0.23-1.32,0.39-2.47,0.62c-0.5,0.19-0.96,0.28-1.48,0.38c-0.25,0.07-0.24,0.07-0.49,0.14c-0.23,0.06-0.68,0.37-0.92,0.28
          c-0.29-0.11-0.1-0.74-0.1-1.04c0-0.26,0.02-0.26,0.02-0.52c-0.01-0.72,0.03-1.37,0-2.09c0-0.26,0.01-0.26,0.01-0.52
          c0-0.34-0.19-0.73-0.01-1.05c0.09-0.16,0.24-0.13,0.46-0.25c0.49-0.26,0.9-0.5,1.37-0.78c0.36-0.16,0.59-0.28,0.93-0.49
          c0.62-0.37,1.21-0.7,1.85-1.01c0.59-0.4,1.2-0.68,1.83-1.03c0.21-0.12,0.32-0.41,0.52-0.14C76.99,39.55,76.8,40.04,76.8,40.26z
           M78.06,37.05c-0.26-0.15-0.57-0.13-0.82,0.03c-0.35,0.16-0.56,0.29-0.87,0.51c-1.12,0.52-1.15,0.67-2.21,1.19
          c-0.36,0.2-0.49,0.47-0.54-0.08c-0.02-0.25-0.05-0.74,0.08-0.97c1.91-3.22,5.89-2.95,7.87,0.04c0.03,0.28,0.07,0.57,0.06,0.86
          c-0.03,0.63-0.12,0.36-0.49,0.15c-0.22-0.12-0.24-0.09-0.46-0.21c-0.45-0.28-0.82-0.53-1.31-0.75
          C78.9,37.54,78.51,37.31,78.06,37.05z M78.43,39.23c0.18-0.15,0.76,0.42,0.96,0.53c0.35,0.19,0.58,0.31,0.93,0.49
          c0.36,0.16,0.59,0.29,0.92,0.51c0.8,0.39,1.5,0.86,2.29,1.28c0.35,0.18,0.58,0.32,0.92,0.51c0.27,0.15,0.79,0.23,0.95,0.49
          c0.13,0.22-0.02,0.27-0.02,0.54c0,0.26,0,0.26,0,0.52c0,0.39,0.01,0.65,0.02,1.04c-0.03,0.39-0.05,0.64-0.02,1.04
          c0.02,0.55,0.02,1.01,0.01,1.56c0,0.2,0.18,0.41-0.06,0.54c-0.96-0.28-2.46-0.7-3.39-0.93c-0.52-0.14-0.97-0.28-1.48-0.37
          c-0.65-0.24-0.82-0.18-1.47-0.41c-0.25-0.06-0.32,0.06-0.48-0.14c-0.16-0.2-0.02-0.25-0.02-0.51c0.01-0.87,0.07-1.71,0-2.58
          c0.03-0.39,0.02-0.65-0.02-1.03c0.01-0.38,0.04-0.64,0.05-1.03c-0.03-0.38-0.05-0.64-0.03-1.03
          C78.46,40.08,78.28,39.36,78.43,39.23z M89.95,59.61c-0.15,0.22-0.25,0.09-0.5,0.18c-0.84,0.29-1.63,0.61-2.49,0.9
          c-0.36,0.17-0.61,0.26-1,0.37c-0.5,0.24-0.96,0.36-1.5,0.53c-0.81,0.36-1.65,0.57-2.49,0.9c-0.36,0.16-0.61,0.26-1,0.37
          c-0.5,0.25-0.96,0.36-1.5,0.53c-0.23,0.08-0.71,0.53-0.97,0.38c-0.24-0.14-0.05-0.8-0.05-1.04c0.01-0.39,0.03-0.66,0.05-1.05
          c0.01-0.73-0.08-1.38-0.03-2.09c-0.03-0.39-0.03-0.66,0-1.05c-0.01-0.39,0-0.66,0.02-1.04c-0.02-1.07,0.01-2.08-0.04-3.14
          c0.1-0.78,0-0.79,0.05-1.57c0-0.26-0.05-0.26-0.05-0.52c0.04-0.41,0.05-0.62,0.01-1.04c0.04-0.45,0.05-0.58,0-1.03
          c0.02-0.33,0.04-0.68,0.03-1.03c0-0.22-0.15-0.52-0.1-0.74c0.12-0.49,0.68-0.06,0.98,0.02c0.34,0.1,0.57,0.15,0.91,0.22
          c0.7,0.2,1.35,0.34,2.02,0.58c0.38,0.09,0.64,0.15,1.02,0.24c0.47,0.08,0.58,0.13,1.01,0.29c0.54,0.14,1,0.22,1.54,0.35
          c0.37,0.13,0.62,0.2,1.01,0.28c0.37,0.11,0.63,0.19,1.01,0.3c0.54,0.13,1,0.19,1.53,0.37c0.73,0.19,0.47,0.53,0.49,1.17
          c0.01,0.26,0.04,0.26,0.04,0.51c-0.07,0.88,0.03,1.76-0.05,2.63c0.03,0.88,0.06,1.75,0,2.63c0.03,0.39,0.04,0.65,0.03,1.05
          C89.96,59.34,90.1,59.39,89.95,59.61z"
      />
      <path
        d="M17.43,24.67c-0.22-1.28-1.19-2.74-2.2-3.55c-1.4-1.08-3.26-1.49-4.98-1.1c-1.08,0.13-1.98,0.75-2.92,1.22
          c-0.24,0.12-0.26,0.08-0.5,0.2c-0.33,0.21-0.56,0.35-0.93,0.52c-0.52,0.2-0.91,0.47-1.43,0.72c-0.49,0.25-0.51,0.16-0.23,0.7
          c0.26,0.49,0.41,0.9,0.68,1.38c0.17,0.35,0.31,0.57,0.51,0.89c0.17,0.34,0.27,0.58,0.43,0.93c0.33,0.62,0.69,1.17,0.95,1.81
          c0.2,0.33,0.31,0.56,0.46,0.92c0.18,0.34,0.32,0.56,0.51,0.89c0.15,0.35,0.25,0.58,0.43,0.93c0.31,0.65,0.68,1.17,0.96,1.81
          c0.12,0.23,0.08,0.25,0.2,0.47c0.26,0.51,0.25,0.42,0.73,0.18c0.36-0.2,0.61-0.3,0.97-0.44c0.45-0.31,0.9-0.51,1.41-0.76
          c0.35-0.21,0.59-0.32,0.96-0.47c1.34-0.66,2.35-1.46,3.21-2.68C17.5,27.88,17.83,26.23,17.43,24.67z M14.88,26.58
          c-0.07,0.35-0.16,0.7-0.25,1.04c-0.59,1.34-2,2.02-3.25,2.56c-0.49,0.25-0.42,0.3-0.68-0.21c-0.21-0.33-0.34-0.57-0.5-0.93
          c-0.28-0.67-0.65-1.22-0.99-1.87c-0.15-0.36-0.27-0.6-0.48-0.94c-0.2-0.52-0.46-0.92-0.73-1.41c-0.12-0.24-0.09-0.25-0.21-0.49
          C7.52,23.79,7.47,23.91,8,23.63c0.35-0.18,0.58-0.32,0.91-0.52c0.47-0.24,0.98-0.41,1.47-0.61c1.65-0.39,3.31,0.51,4.01,2.03
          C14.72,25.16,14.95,25.86,14.88,26.58z"
      />
      <path
        d="M32.88,22.76c-0.65-0.62-1.17-1.27-1.84-1.82c-0.25-0.29-0.43-0.48-0.72-0.75c-0.26-0.3-0.45-0.47-0.75-0.72
          c-0.59-0.6-0.79-0.89-1.45-1.47c-0.39-0.39-0.69-0.74-1.06-1.13c-0.39-0.39-0.74-0.68-1.11-1.09c-0.61-0.52-0.85-0.87-1.37-1.39
          c1.93,0.38,2.7-2.26,0.94-3.04c-1.38-0.56-2.78,0.95-2.02,2.27c-0.2,0.18-0.44,0.21-0.69,0.32c-0.4,0.18-0.82,0.41-1.24,0.49
          c-0.59-1.74-3.18-1.29-3.15,0.55c0.06,1.59,2.21,2.19,2.98,0.76c0.04,0.28,0,0.56-0.01,0.86c0.03,0.7-0.02,1.35-0.03,2.07
          c0.01,0.4-0.01,0.66-0.06,1.03c-0.01,0.39-0.01,0.65-0.02,1.03c0,0.71,0.05,1.38-0.04,2.07c0.03,0.39,0.02,0.65-0.02,1.03
          c-0.01,0.72-0.06,1.38-0.02,2.07c-0.02,0.55-0.07,1.01-0.03,1.55c0,0.2-0.03,0.42,0.04,0.55c0.08,0.12,0.33-0.18,0.45-0.23
          c0.56-0.21,0.97-0.47,1.53-0.62c0.26-0.1,0.4-0.03,0.53-0.19c0.14-0.16-0.01-0.26-0.01-0.51c0-0.25,0.01-0.25,0.01-0.5
          c-0.02-0.52-0.02-0.96-0.02-1.49c0-0.25-0.14-0.3,0-0.5c0.15-0.21,0.24-0.11,0.48-0.2c0.24-0.1,0.26-0.06,0.5-0.16
          c0.5-0.23,0.92-0.44,1.44-0.59c0.52-0.2,0.92-0.41,1.44-0.6c0.24-0.1,0.23-0.12,0.47-0.21c0.24-0.1,0.24-0.22,0.49-0.17
          c0.24,0.05,0.19,0.17,0.36,0.35c0.25,0.27,0.41,0.47,0.66,0.74c0.26,0.27,0.45,0.44,0.72,0.68c0.17,0.18,0.22,0.35,0.33,0.37
          c0.1,0.01,0.27-0.06,0.52-0.21c0.38-0.19,0.68-0.23,1.06-0.39c0.27-0.12,0.85-0.38,1-0.49C33.18,22.96,32.96,22.84,32.88,22.76z
           M26.26,20.37c-0.52,0.26-0.98,0.37-1.52,0.6c-0.25,0.1-0.25,0.12-0.5,0.22c-0.19,0.08-0.46,0.32-0.54,0.17
          c-0.08-0.14,0-0.39,0-0.53c0.03-0.4,0.03-0.67,0.02-1.08c0.02-0.57,0.07-1.04,0.04-1.61c0-0.27-0.03-0.27-0.03-0.54
          c0-0.1-0.1-0.47,0.03-0.54c0.41-0.03,0.45,0.59,0.73,0.79c0.29,0.28,0.48,0.47,0.77,0.76c0.4,0.4,0.73,0.75,1.13,1.15
          C26.84,20.22,26.81,20.15,26.26,20.37z"
      />
      <path
        d="M47.5,17.99c-0.25-0.65-0.36-1.31-0.6-1.96c-0.07-0.38-0.14-0.62-0.27-0.99c-0.07-0.25-0.04-0.26-0.1-0.5
          c-0.22-0.84-0.48-1.63-0.67-2.47c-0.09-0.38-0.18-0.62-0.3-0.98c-0.09-0.38-0.17-0.62-0.29-0.98C45.19,9.62,45.18,9.57,45,9.11
          c-0.11-0.52-0.21-0.98-0.39-1.49c-0.07-0.25-0.04-0.41-0.14-0.49c-0.08-0.07-0.24,0.04-0.47,0.1c-0.49,0.14-0.9,0.3-1.4,0.4
          c-0.23,0.06-0.23,0.09-0.46,0.16c-0.23,0.06-0.37-0.01-0.46,0.12c-0.2,0.58-0.35,1.34-0.57,1.95c-0.19,0.69-0.45,1.28-0.57,1.96
          c-0.13,0.35-0.21,0.6-0.3,0.97c-0.1,0.29-0.16,0.78-0.3,0.99c-0.06,0.06-0.21-0.17-0.4-0.34c-0.39-0.38-0.68-0.73-1.11-1.06
          c-0.27-0.28-0.47-0.44-0.76-0.68c-0.6-0.62-1.25-1.14-1.86-1.75c-0.19-0.17-0.13-0.3-0.37-0.36c-0.24-0.06-0.23,0.11-0.47,0.18
          c-0.23,0.06-0.25,0.02-0.48,0.08c-0.43,0.16-0.48,0.18-0.94,0.25c-0.23,0.07-0.23,0.08-0.46,0.15c-0.24,0.07-0.41,0.01-0.47,0.14
          c-0.06,0.12,0.06,0.25,0.13,0.5c0.07,0.25,0.11,0.24,0.18,0.48c0.09,0.38,0.14,0.63,0.22,1c0.18,0.69,0.4,1.29,0.55,1.98
          c0.12,0.54,0.29,0.95,0.45,1.47c0.1,0.52,0.23,0.97,0.39,1.49c0.14,0.53,0.25,0.98,0.42,1.48c0.08,0.5,0.08,0.5,0.27,0.99
          c0.07,0.38,0.13,0.62,0.27,0.99c0.07,0.43,0.34,1.12,0.4,1.48c0.18,0.07,0.25-0.05,0.51-0.12c0.26-0.07,0.25-0.08,0.51-0.15
          c0.39-0.1,0.64-0.17,1.02-0.28c0.26-0.07,0.47-0.02,0.52-0.14c0.05-0.12-0.07-0.26-0.15-0.52c-0.1-0.38-0.18-0.64-0.3-1.02
          c-0.22-0.88-0.46-1.68-0.7-2.57c-0.28-1.05-0.52-2.06-0.88-3.06c-0.07-0.26-0.23-0.46-0.1-0.51c0.12-0.05,0.18,0.17,0.38,0.34
          c0.42,0.34,0.77,0.65,1.19,1.01c0.27,0.28,0.46,0.46,0.77,0.7c0.54,0.45,1.07,0.86,1.56,1.36c0.28,0.26,0.45,0.46,0.79,0.68
          c0.03,0.02,0.05,0.01,0.06-0.02c0.23-0.52,0.27-0.98,0.48-1.5c0.21-0.51,0.31-0.96,0.49-1.5c0.14-0.54,0.33-0.97,0.46-1.5
          c0.21-0.47,0.23-0.5,0.34-0.99c0.08-0.25,0.06-0.5,0.17-0.48c0.12,0.02,0.04,0.25,0.12,0.51c0.11,0.39,0.19,0.64,0.29,1.03
          c0.15,0.54,0.29,1,0.45,1.54c0.15,0.55,0.24,1.03,0.44,1.54c0.14,0.39,0.2,0.65,0.26,1.04c0.16,0.6,0.38,1.51,0.59,2.05
          c0.2,0.05,0.65-0.27,1.02-0.3c0.39-0.08,0.65-0.13,1.05-0.25c0.26-0.07,0.46-0.02,0.52-0.15c0.05-0.11-0.07-0.25-0.13-0.5
          C47.55,18.24,47.57,18.24,47.5,17.99z"
      />
      <path
        d="M50.03,7c0.07,0.7,0.18,1.34,0.26,2.03c0.09,0.54,0.21,0.98,0.24,1.52c0.1,0.44,0.12,0.58,0.13,1.02
          c0.16,0.54,0.16,0.94,0.28,1.51c0.02,0.85,0.24,1.67,0.36,2.54c0.11,0.47,0.11,0.52,0.13,1.02c0.13,0.44,0.19,1.11,0.24,1.53
          c0.03,0.07,0.27-0.07,0.53-0.11c0.53-0.03,0.54-0.04,1.05-0.17c0.37-0.05,0.67,0.03,1.04-0.14c0.06-0.03-0.05-0.25-0.09-0.51
          c-0.04-0.26,0.01-0.27-0.03-0.53c-0.15-0.7-0.24-1.38-0.33-2.1c-0.08-0.38-0.14-0.64-0.19-1.05c-0.04-0.4-0.09-0.66-0.16-1.05
          c-0.01-0.56-0.11-1.03-0.2-1.58c-0.04-0.26-0.04-0.26-0.08-0.53c-0.04-0.26-0.17-0.36-0.1-0.54c0.03-0.09,0.17,0.23,0.34,0.42
          c0.38,0.34,0.41,0.37,0.71,0.76c0.25,0.29,0.43,0.49,0.69,0.77c0.24,0.31,0.43,0.5,0.71,0.75c0.32,0.43,0.64,0.78,1.02,1.17
          c0.17,0.19,0.2,0.17,0.37,0.36c0.17,0.19,0.11,0.25,0.31,0.42c0.02,0.02,0.06,0,0.08-0.03c0.2-0.34,0.27-0.6,0.43-0.96
          c0.18-0.34,0.31-0.57,0.46-0.94c0.26-0.49,0.46-0.93,0.64-1.44c0.17-0.36,0.3-0.59,0.49-0.93c0.16-0.36,0.26-0.6,0.42-0.96
          c0.11-0.24,0.16-0.53,0.22-0.5c0.1,0.04,0.06,0.29,0.1,0.55c0.04,0.26,0.03,0.27,0.07,0.53c0.21,1.25,0.32,2.47,0.53,3.71
          c0.13,0.72,0.21,1.4,0.3,2.12c0.08,0.36,0.06,0.74,0.2,1.04c0,0,0.25,0.01,0.51-0.09c0.39-0.12,0.69-0.07,1.08-0.12
          c0.26-0.04,0.26-0.04,0.53-0.08c0.26-0.04,0.45,0.05,0.55-0.09c0.07-0.1-0.08-0.27-0.12-0.52c-0.05-0.86-0.3-1.7-0.34-2.54
          c-0.15-0.5-0.21-0.99-0.24-1.52c-0.05-0.39-0.1-0.64-0.18-1.01c-0.05-0.53-0.11-1.01-0.24-1.52c-0.02-0.57-0.01-0.46-0.15-1.01
          c-0.05-0.39-0.06-0.64-0.1-1.02c-0.14-0.69-0.21-1.34-0.35-2.02c-0.04-0.25,0-0.26-0.04-0.51c-0.04-0.25,0.07-0.39-0.08-0.53
          c-0.36,0.07-1.05,0.23-1.46,0.23c-0.35,0.07-0.59,0.13-0.95,0.19c-0.24,0.04-0.38,0.01-0.47,0.07c-0.21,0.14-0.12,0.21-0.23,0.44
          c-0.16,0.35-0.26,0.58-0.4,0.94c-0.18,0.51-0.38,0.93-0.63,1.39c-0.18,0.5-0.35,0.93-0.59,1.41c-0.12,0.37-0.21,0.6-0.39,0.94
          c-0.1,0.23-0.11,0.46-0.2,0.45c-0.21-0.1-0.45-0.55-0.65-0.77c-0.47-0.52-0.94-0.99-1.32-1.56c-0.26-0.27-0.43-0.47-0.66-0.77
          c-0.38-0.38-0.62-0.77-1-1.16c-0.17-0.19-0.09-0.29-0.33-0.39c-0.23-0.09-0.24,0.06-0.48,0.1c-0.24,0.04-0.24,0.01-0.48,0.05
          c-0.51,0.07-0.96,0.1-1.44,0.23c-0.24,0.04-0.47-0.04-0.5,0.04C49.8,6.19,49.97,6.73,50.03,7z"
      />
      <path
        d="M67.17,16.02c0.58-0.04,1.08-0.06,1.65-0.05c0.28-0.01,0.47,0.08,0.55-0.04c0.18-0.56,0.47-1.28,0.7-1.86
          c0.06-0.24,0.24-0.76,0.35-0.91c0.16-0.08,0.32-0.03,0.49-0.03c0.69-0.1,1.36-0.05,2.08-0.08c0.39-0.05,0.65-0.06,1.04-0.04
          c0.39-0.03,0.66-0.03,1.04-0.02c0.26-0.01,0.39-0.13,0.51-0.02c0.17,0.2,0.26,0.62,0.4,0.89c0.28,0.54,0.57,1.29,0.83,1.82
          c0.14,0.13,0.74-0.13,1.14-0.06c0.43-0.01,0.71-0.02,1.13-0.02c0.28-0.01,0.48,0.04,0.55-0.06c0.07-0.12-0.09-0.22-0.2-0.46
          c-0.11-0.24-0.14-0.22-0.25-0.46c-0.16-0.52-0.41-0.91-0.66-1.41c-0.13-0.36-0.24-0.6-0.43-0.94c-0.44-0.97-0.83-1.88-1.33-2.8
          c-0.24-0.67-0.61-1.24-0.84-1.89c-0.15-0.37-0.27-0.6-0.47-0.92c-0.16-0.36-0.25-0.6-0.4-0.96c-0.24-0.5-0.49-0.88-0.67-1.4
          c-0.11-0.23-0.09-0.24-0.2-0.48c-0.11-0.23-0.09-0.41-0.23-0.48c-0.14-0.07-0.26,0.01-0.52,0.02c-0.25,0.01-0.25,0.06-0.5,0.07
          c-0.38,0.01-0.63,0-1-0.01c-0.25,0.01-0.32-0.06-0.51,0.02c-0.07,0.03-0.06,0.27-0.15,0.51c-0.15,0.36-0.26,0.6-0.41,0.95
          c-0.21,0.52-0.3,0.97-0.55,1.45c-0.12,0.37-0.21,0.62-0.38,0.96c-0.16,0.52-0.37,0.93-0.54,1.45C69.26,9.21,69.2,9.35,69,9.74
          c-0.28,0.66-0.44,1.3-0.75,1.93c-0.31,0.83-0.59,1.6-0.88,2.43c-0.28,0.54-0.54,1.41-0.76,1.93
          C66.69,16.07,66.89,16.04,67.17,16.02z M71.48,10.2c0.34-0.85,0.58-1.67,0.92-2.51c0.18-0.37,0.09-0.72,0.36-1
          c0.27-0.01,0.35,0.76,0.44,0.96c0.11,0.24,0.11,0.25,0.22,0.49c0.28,0.71,0.63,1.31,0.99,1.99c0.07,0.15,0.22,0.34,0.19,0.43
          c-0.04,0.12-0.44,0.06-0.6,0.06c-0.27,0.01-0.27-0.01-0.55,0c-0.59,0-1.06,0.09-1.63,0.06c-0.16,0.01-0.45,0.06-0.58-0.02
          C71.23,10.67,71.4,10.33,71.48,10.2z"
      />
      <path
        d="M82.96,15.72c0.56-0.01,1.03,0.09,1.6,0.1c0.27,0.02,0.36,0.12,0.55,0.04c0.11-0.05,0.01-0.27,0.02-0.53
          c0.06-0.38,0.08-0.65,0.08-1.04c0.06-0.55,0.04-1,0.09-1.56c0.04-0.86,0.15-0.72,0.1-1.56c0.16-0.76,0.06-0.79,0.11-1.56
          c0.05-0.38,0.08-0.64,0.11-1.04c0.02-0.26-0.05-0.47,0.04-0.5c0.12-0.04,0.13,0.2,0.28,0.41c0.14,0.21,0.13,0.23,0.27,0.44
          c0.29,0.49,0.6,0.79,0.89,1.28c0.21,0.33,0.34,0.56,0.53,0.89c0.23,0.3,0.4,0.51,0.61,0.84c0.43,0.74,0.89,1.47,1.39,2.18
          c0.2,0.34,0.35,0.55,0.59,0.85c0.21,0.33,0.35,0.55,0.57,0.86c0.14,0.22,0.04,0.31,0.27,0.44c0.25,0.14,0.28,0.07,0.57,0.05
          c0.43,0,0.72,0.06,1.15,0.05c0.29,0.02,0.44,0.13,0.56,0.04c0.2-0.16,0.05-0.23,0.07-0.49c0.02-0.26-0.01-0.26,0-0.52
          c0.08-0.49,0.08-0.53,0.07-1.02c0.11-0.56,0.09-0.48,0.08-1.02c0.02-0.72,0.15-1.33,0.18-2.04c0.07-0.55,0.02-0.98,0.08-1.54
          c0.08-1.05,0.11-2.05,0.26-3.06c-0.06-0.72,0.09-1.34,0.11-2.05c0.12-0.67,0.18-0.53-0.49-0.55c-0.56-0.07-1.04-0.09-1.6-0.1
          c-0.27-0.02-0.53-0.13-0.55-0.06c0.02,0.95-0.12,2.07-0.19,3.04c-0.01,0.39-0.04,0.64-0.1,1.01c0.02,0.53,0.02,0.48-0.08,1.01
          c0.01,0.71-0.1,0.81-0.08,1.51c-0.04,0.21,0.05,0.99-0.08,0.98c-0.06,0.02-0.13-0.21-0.27-0.43c-0.22-0.32-0.38-0.54-0.6-0.87
          c-0.19-0.34-0.34-0.57-0.57-0.89c-0.2-0.35-0.35-0.57-0.6-0.87c-0.25-0.49-0.24-0.46-0.58-0.88c-0.3-0.47-0.58-0.85-0.83-1.35
          c-0.22-0.34-0.38-0.55-0.62-0.86C86.64,4.87,86.38,4.47,86.07,4c-0.15-0.22-0.17-0.38-0.29-0.45c-0.08-0.05-0.25,0.01-0.5-0.01
          c-0.36-0.05-0.61-0.09-0.99-0.12c-0.25-0.02-0.25,0.02-0.5,0.01c-0.25-0.02-0.37-0.14-0.49-0.05c-0.12,0.19-0.03,0.74-0.06,1.02
          C83.2,5.45,83,6.43,82.99,7.48c-0.04,0.87-0.13,1.69-0.19,2.55c-0.06,0.71-0.02,1.36-0.14,2.04c0.03,0.65-0.08,0.87-0.11,1.53
          c0.04,0.66-0.17,1.83-0.12,2.05C82.49,15.74,82.69,15.7,82.96,15.72z"
      />
      <path
        d="M97.47,15.84c1.18,0.98,2.57,1.89,4.14,2.01c1.46,0.36,3.24,0.05,4.27-1.11c0.39-0.55,0.69-1.18,0.8-1.86
          c0-0.02,0.03-0.01,0.03-0.03c0.46-2.2-1.44-3.54-3.22-4.31c-0.7-0.27-1.42-0.59-1.96-1.05c-0.38-0.28-0.47-0.57-0.38-1.01
          c0.15-0.79,1.05-0.91,1.74-0.76c1.16,0.16,2.16,0.88,2.96,1.72c0.22-0.11,0.17-0.18,0.35-0.36c0.26-0.27,0.43-0.45,0.68-0.72
          c0.22-0.25,0.59-0.49,0.69-0.71c-2.24-2.77-8.25-3.82-9.1,0.65c-0.38,1.39,0.38,2.74,1.55,3.48c0.31,0.13,0.61,0.3,0.89,0.51
          c0.63,0.25,1.31,0.53,1.9,0.84c0.78,0.35,1.7,1.16,1.02,1.99c-0.41,0.52-1.14,0.48-1.75,0.4c-1.37-0.11-2.46-1.1-3.36-2.05
          c-0.23,0.09-0.21,0.13-0.4,0.29c-0.28,0.25-0.46,0.42-0.74,0.67c-0.19,0.16-0.18,0.17-0.37,0.33c-0.19,0.16-0.29,0.08-0.42,0.29
          C96.99,15.32,97.22,15.59,97.47,15.84z"
      />
      <path
        d="M126.48,11.31c-0.52-0.1-0.97-0.26-1.5-0.43c-0.52-0.18-0.99-0.28-1.48-0.49c-0.53-0.12-1-0.25-1.49-0.46
          c-0.38-0.08-0.63-0.16-0.99-0.3c-0.54-0.11-0.5-0.1-0.99-0.32c-0.38-0.1-0.63-0.16-1.01-0.26c-0.32-0.08-0.72-0.3-0.99-0.32
          c-0.07,0.01-0.07,0.24-0.14,0.49c-0.08,0.25-0.08,0.25-0.16,0.49c-0.19,0.51-0.25,0.95-0.43,1.48c-0.14,0.36-0.21,0.6-0.31,0.98
          c-0.07,0.25-0.11,0.23-0.19,0.48c-0.08,0.37-0.15,0.62-0.28,0.99c-0.09,0.38-0.18,0.62-0.32,0.97c-0.24,0.84-0.49,1.62-0.76,2.45
          c-0.22,0.68-0.35,1.31-0.59,1.96c-0.13,0.52-0.22,0.95-0.45,1.47c0.77,0.15,1.76,0.61,2.58,0.77c0.26-0.73,0.55-1.74,0.76-2.52
          c0.23-0.56,0.5-1.47,0.64-2.02c0.32,0.06,0.36,0.19,1.03,0.32c0.68,0.24,1.34,0.47,2.04,0.62c0.67,0.29,1.36,0.36,2.03,0.64
          c0.25,0.08,0.43,0.2,0.51,0.15c0.14-0.1,0.08-0.23,0.15-0.46c0.16-0.49,0.31-0.92,0.42-1.41c0.07-0.24,0.24-0.33,0.14-0.46
          c-0.35-0.13-1.09-0.37-1.51-0.5c-0.54-0.13-1.01-0.27-1.53-0.47c-0.71-0.14-1.33-0.42-2.05-0.6c-0.67-0.15-0.52-0.09-0.35-0.66
          c0.17-0.58,0.43-1.46,0.62-1.98c0.46,0.03,0.5,0.2,0.97,0.29c0.38,0.09,0.62,0.18,0.97,0.32c0.84,0.24,1.62,0.49,2.46,0.72
          c0.7,0.27,0.78,0.18,1.46,0.49c0.53,0.2,0.51,0.26,0.65-0.34c0.15-0.5,0.33-0.9,0.39-1.42c0.07-0.23,0.28-0.44,0.2-0.47
          C126.75,11.38,126.73,11.39,126.48,11.31z"
      />
      <path
        d="M138.56,18.41c0.53-0.46,1.12-0.77,1.67-1.21c0.21-0.15,0.53-0.26,0.43-0.3c-0.55-0.19-0.42-0.11-0.95-0.41
          c-0.36-0.14-0.6-0.22-0.96-0.36c-0.24-0.09-0.23-0.1-0.47-0.2c-0.24-0.1-0.22-0.23-0.48-0.2c-0.26,0.04-0.22,0.14-0.43,0.3
          c-0.21,0.16-0.21,0.17-0.42,0.32c-0.45,0.33-0.85,0.6-1.24,0.97c-0.46,0.3-0.86,0.57-1.27,0.93c-0.32,0.22-0.54,0.36-0.86,0.6
          c-0.21,0.16-0.23,0.39-0.42,0.35c-0.02-0.16-0.12-0.79-0.18-1.05c-0.04-0.26,0.02-0.27-0.02-0.52c-0.04-0.26-0.09-0.25-0.13-0.51
          c-0.02-0.39-0.03-0.65-0.11-1.04c-0.11-0.55-0.13-1.02-0.27-1.54c-0.06-0.22,0-0.96-0.14-1.03c-0.47-0.15-1-0.41-1.45-0.62
          c-0.4-0.13-1.17-0.42-1.49-0.59c0.04,0.4,0.13,0.66,0.2,1.05c0.06,0.54,0.18,0.99,0.28,1.53c0.2,1.04,0.4,2.03,0.54,3.07
          c0.12,0.98,0.28,1.21,0.38,2.04c0.05,0.25,0.02,0.26,0.06,0.51c0.05,0.26,0.16,0.25,0.13,0.51c-0.03,0.27-0.09,0.26-0.2,0.5
          c-0.24,0.71-0.57,1.29-0.83,1.99c-0.15,0.37-0.27,0.62-0.43,0.99c-0.13,0.4-0.18,0.61-0.4,1.02c-0.03,0.06,0.27,0.11,0.52,0.21
          c0.25,0.1,0.25,0.1,0.5,0.21c0.37,0.17,0.62,0.27,1.01,0.4c0.25,0.1,0.46,0.26,0.5,0.2c0.15-0.25,0.11-0.25,0.21-0.5
          c0.17-0.38,0.27-0.63,0.39-1.02c0.22-0.53,0.41-0.99,0.65-1.5c0.21-0.47,0.35-1.14,0.62-1.5c0.16-0.2,0.19-0.16,0.41-0.29
          c0.33-0.21,0.52-0.39,0.83-0.62c0.31-0.24,0.52-0.38,0.85-0.59c0.45-0.32,0.8-0.63,1.27-0.89c0.33-0.21,0.53-0.38,0.82-0.63
          C138.02,18.77,138.25,18.65,138.56,18.41z"
      />
      <path
        d="M151.47,24.67c0.03-1.12-0.62-2.04-1.39-2.8c-0.69-0.57-1.49-0.95-2.26-1.35c-0.89-0.51-1.8-0.97-2.73-1.4
          c-0.32-0.21-0.55-0.33-0.9-0.47c-0.23-0.12-0.2-0.17-0.44-0.22c-0.09-0.02-0.15,0.19-0.27,0.42c-0.12,0.23-0.07,0.25-0.19,0.48
          c-0.12,0.23-0.12,0.22-0.24,0.45c-0.21,0.32-0.34,0.54-0.5,0.9c-0.28,0.48-0.4,0.9-0.68,1.38c-0.35,0.59-0.67,1.18-0.95,1.81
          c-0.21,0.33-0.32,0.56-0.46,0.91c-0.22,0.34-0.32,0.53-0.48,0.91c-0.24,0.49-0.47,0.88-0.71,1.36c-0.41,0.57-0.6,1.19-0.94,1.82
          c-0.12,0.23-0.31,0.33-0.26,0.44c0.07,0.16,0.23,0.14,0.47,0.27c0.52,0.21,0.97,0.43,1.46,0.72c0.24,0.12,0.35,0.3,0.47,0.23
          c0.09-0.04,0.32-0.66,0.46-0.85c0.34-0.59,0.57-1.18,0.93-1.73c0.12-0.19,0.3-0.89,0.43-0.89c0.18-0.05,0.24,0.11,0.48,0.24
          c0.36,0.18,0.6,0.31,0.94,0.5c0.24,0.12,0.33,0.02,0.48,0.24c0.15,0.22,0,0.27,0.02,0.53c0.21,1.2,0.28,2.44,0.46,3.66
          c0.03,0.26-0.13,0.32,0.04,0.52c0.1,0.12,0.21,0.15,0.47,0.24c0.25,0.09,0.22,0.15,0.46,0.27c0.56,0.29,1.41,0.65,1.9,0.97
          c0.1-0.09-0.02-0.29-0.05-0.55c-0.06-0.57-0.17-1.02-0.24-1.58c-0.07-0.9-0.22-1.74-0.32-2.64c-0.03-0.26-0.12-0.28-0.07-0.54
          c1.67,0.33,3.45-0.59,4.12-2.17C151.29,26.12,151.56,25.41,151.47,24.67z M148.68,25.11c-0.16,0.48-0.43,1.02-0.9,1.26
          c-0.62,0.26-1.37,0.17-1.98-0.14c-0.65-0.37-1.27-0.67-1.93-1.04c-0.24-0.12-0.52-0.13-0.48-0.26c0.08-0.23,0.11-0.22,0.23-0.45
          c0.16-0.35,0.29-0.57,0.49-0.88c0.22-0.54,0.66-1.33,0.9-1.82c0.27,0.06,0.25,0.14,0.48,0.26c0.37,0.18,0.6,0.32,0.94,0.53
          C147.46,23.01,148.97,23.72,148.68,25.11z"
      />
      <path
        d="M94.51,43.03c0.08,0.08,0.11-0.02,0.22-0.02c0.42,0.05,0.85,0,1.28,0.02c0.16,0,0.27,0,0.43,0.01
          c0.11,0,0.16-0.1,0.22-0.01c0.06,0.08-0.07,0.11-0.22,0.37c-0.08,0.19-0.19,0.37-0.29,0.56c-0.15,0.25-0.26,0.5-0.43,0.73
          c-0.12,0.26-0.25,0.49-0.38,0.75c-0.05,0.09-0.07,0.08-0.12,0.17c-0.09,0.2-0.18,0.37-0.31,0.55c-0.06,0.14-0.1,0.24-0.18,0.38
          c-0.05,0.09-0.17,0.09-0.11,0.18c0.05,0.09,0.14-0.01,0.45-0.01c0.26-0.01,0.59,0.06,0.67,0c0.12-0.08,0.12-0.23,0.19-0.36
          c0.08-0.13,0.14-0.21,0.2-0.36c0.05-0.09,0.04-0.1,0.09-0.19c0.05-0.09,0.06-0.08,0.11-0.18c0.12-0.19,0.17-0.36,0.3-0.54
          c0.06-0.14,0.1-0.23,0.19-0.36c0.07-0.14,0.11-0.23,0.18-0.37c0.13-0.26,0.26-0.47,0.4-0.72c0.09-0.19,0.17-0.36,0.27-0.55
          c0.22-0.26,0.06-0.21,0.11-0.55c-0.02-0.26,0.07-0.32-0.01-0.37c-0.08-0.07-0.1,0-0.21,0c-0.34-0.01-0.69-0.02-1.02,0.01
          c-0.22,0-0.4-0.02-0.61,0c-0.21-0.03-0.39,0-0.61,0c-0.13-0.01-0.27-0.01-0.41-0.01c-0.29,0.03-0.33-0.07-0.4,0.01
          c-0.07,0.08-0.03,0.1-0.01,0.21c0.01,0.16-0.01,0.27,0.01,0.43C94.53,42.91,94.43,42.95,94.51,43.03z"
      />
      <path
        d="M102.51,44.92c-0.07-0.07-0.1,0-0.2,0c-0.28-0.02-0.35,0.08-0.41,0c-0.07-0.06,0.01-0.17,0-0.43
          c-0.02-0.16-0.02-0.27,0-0.43c-0.03-0.23,0.01-0.42-0.01-0.64c-0.01-0.16,0-0.27,0.01-0.43c-0.02-0.16-0.02-0.27-0.01-0.43
          c0-0.11,0-0.11,0-0.22s0.08-0.14,0.01-0.22c-0.08-0.08-0.1-0.03-0.22,0c-0.15,0.01-0.26-0.01-0.42,0.01
          c-0.11,0-0.11-0.05-0.21-0.01c-0.11,0.05-0.04,0.11-0.28,0.31c-0.13,0.18-0.27,0.3-0.41,0.48c-0.09,0.13-0.16,0.21-0.28,0.32
          c-0.14,0.18-0.28,0.31-0.41,0.49c-0.1,0.12-0.18,0.19-0.28,0.32c-0.21,0.2-0.35,0.43-0.56,0.64c-0.14,0.2-0.26,0.23-0.28,0.32
          c-0.03,0.09,0.07,0.21,0.11,0.47c0.03,0.12-0.03,0.15,0.06,0.23c0.07,0.07,0.09,0,0.2,0c0.15-0.01,0.25-0.01,0.4,0
          c0.15,0.01,0.25,0.01,0.4,0c0.15,0.01,0.25,0.01,0.41,0c0.22-0.02,0.39,0.02,0.61,0.01c0.1,0,0.13-0.07,0.2,0
          c0.07,0.07-0.01,0.1-0.01,0.19c0.01,0.2,0.01,0.39-0.01,0.59c0,0.1-0.05,0.12,0.02,0.19c0.07,0.07,0.09,0,0.18,0
          c0.14,0.01,0.24,0.01,0.38,0c0.29-0.02,0.32,0.08,0.39,0.01c0.07-0.07,0-0.1,0-0.2c-0.02-0.2,0-0.38,0-0.59
          c0-0.1-0.08-0.13-0.01-0.2c0.07-0.07,0.1,0.01,0.21,0.01c0.31-0.04,0.32,0.06,0.4-0.01c0.08-0.06,0-0.17,0.01-0.4
          C102.5,45.08,102.58,44.98,102.51,44.92z M100.96,43.9c-0.02,0.27-0.02,0.53-0.01,0.81c0,0.1,0.07,0.13,0,0.2
          c-0.07,0.07-0.1,0.01-0.2,0.01c-0.22-0.01-0.4,0.01-0.61-0.01c-0.27,0-0.36,0.1-0.39,0c-0.04-0.1,0.06-0.08,0.12-0.16
          c0.07-0.08,0.08-0.07,0.15-0.15c0.13-0.17,0.25-0.32,0.4-0.47c0.09-0.13,0.15-0.21,0.26-0.32c0.07-0.08,0.07-0.08,0.13-0.16
          c0.07-0.08,0.04-0.18,0.14-0.15c0.1,0.03,0.03,0.09,0,0.2C100.92,43.8,100.96,43.8,100.96,43.9z"
      />
      <path
        d="M52.74,46.7c0.06,0.08,0.14-0.01,0.39,0.01c0.15,0.01,0.25,0,0.39-0.02c0.1,0,0.12,0.08,0.19,0
          c0.07-0.05,0-0.24,0.02-0.41c-0.02-0.28-0.01-0.55-0.01-0.83c0.02-0.22,0-0.4,0-0.62c0.01-0.16,0.01-0.26-0.01-0.42
          c0.02-0.28,0.01-0.54,0.02-0.83c-0.02-0.15-0.02-0.26-0.01-0.42c0-0.22-0.02-0.4-0.01-0.62c-0.02-0.3,0.08-0.34,0-0.41
          c-0.07-0.08-0.21,0-0.45,0c-0.26-0.04-0.35,0.04-0.6,0.11c-0.26,0.08-0.5,0.13-0.75,0.22c-0.09,0.03-0.13-0.02-0.18,0.06
          c-0.05,0.09,0.01,0.1,0.03,0.2c0.05,0.15,0.08,0.25,0.13,0.4c0.03,0.1-0.05,0.15,0.04,0.21c0.09,0.05,0.1-0.03,0.2-0.06
          c0.15-0.02,0.25-0.05,0.4-0.1c0.1-0.02,0.13-0.11,0.2-0.05c0.08,0.07-0.01,0.11-0.01,0.22c0,0.43,0.03,0.84,0,1.26
          c0.04,0.35-0.01,0.69,0.01,1.05c-0.02,0.22-0.01,0.41,0,0.63C52.75,46.5,52.67,46.65,52.74,46.7z"
      />
      <path
        d="M55.81,44.55c-0.11,0.09-0.19,0.2-0.24,0.32c-0.06,0.12-0.1,0.25-0.12,0.38c-0.05,0.27-0.01,0.56,0.15,0.78
          c0.05,0.13,0.14,0.23,0.26,0.31c0.05,0.04,0.1,0.09,0.16,0.13c0.29,0.18,0.63,0.26,0.97,0.3c0.54,0.02,1.14-0.05,1.55-0.43
          c0.33-0.26,0.45-0.71,0.39-1.11c-0.02-0.13-0.07-0.25-0.13-0.37c-0.03-0.06-0.06-0.12-0.11-0.17c-0.04-0.05-0.1-0.09-0.15-0.13
          c-0.05-0.04-0.11-0.07-0.17-0.11c-0.05-0.03-0.11-0.08-0.18-0.11c0.42-0.23,0.67-0.73,0.58-1.21c-0.01-0.07-0.04-0.13-0.06-0.2
          c-0.02-0.06-0.05-0.13-0.09-0.19c-0.04-0.06-0.08-0.11-0.13-0.16c-0.09-0.11-0.2-0.19-0.32-0.26c-0.06-0.03-0.13-0.05-0.19-0.08
          c-0.06-0.03-0.12-0.06-0.19-0.08c-0.06-0.02-0.13-0.04-0.2-0.05c-0.34-0.06-0.69-0.02-1.02,0.05c-0.07,0.02-0.13,0.04-0.2,0.07
          c-0.06,0.03-0.12,0.07-0.18,0.11c-0.06,0.04-0.12,0.07-0.17,0.11c-0.12,0.08-0.2,0.18-0.26,0.31c-0.23,0.31-0.24,0.83-0.03,1.16
          c0.11,0.17,0.26,0.3,0.43,0.42C56.04,44.41,55.92,44.47,55.81,44.55z M56.55,43.27c0.02-0.06,0.07-0.1,0.12-0.15
          c0.04-0.04,0.09-0.09,0.15-0.12c0.05-0.03,0.11-0.04,0.18-0.06c0.11-0.03,0.26-0.03,0.37,0c0.32,0.05,0.55,0.4,0.44,0.71
          c-0.02,0.06-0.07,0.11-0.11,0.16c-0.04,0.05-0.09,0.08-0.15,0.11c-0.05,0.03-0.11,0.05-0.18,0.07c-0.11,0.03-0.27,0.04-0.38,0.02
          c-0.07-0.02-0.12-0.06-0.17-0.09C56.55,43.82,56.5,43.51,56.55,43.27z M56.43,45.16c0.07-0.12,0.2-0.21,0.33-0.28
          c0.2-0.07,0.43-0.1,0.64-0.06c0.22,0.02,0.42,0.15,0.52,0.34c0.07,0.12,0.07,0.29,0.01,0.42c-0.03,0.06-0.08,0.12-0.14,0.17
          c-0.11,0.09-0.25,0.14-0.4,0.16c-0.13,0.02-0.3,0.04-0.43,0.02c-0.14-0.04-0.28-0.1-0.4-0.18C56.41,45.62,56.36,45.36,56.43,45.16z
          "
      />
      <path
        d="M32.4,75.5c-0.06-0.12-0.12-0.24-0.2-0.35c-0.04-0.05-0.1-0.09-0.15-0.14c-0.05-0.05-0.09-0.1-0.14-0.14
          c-0.05-0.04-0.11-0.07-0.17-0.11c-0.06-0.03-0.11-0.07-0.18-0.1c-0.06-0.03-0.13-0.04-0.19-0.06c-0.19-0.03-0.4-0.06-0.6-0.04
          c-0.07,0.01-0.13,0.03-0.2,0.05c-0.07,0.02-0.13,0.03-0.19,0.06c-0.06,0.02-0.12,0.06-0.18,0.09c-0.11,0.07-0.22,0.16-0.32,0.25
          c-0.18,0.2-0.33,0.43-0.4,0.69c-0.02,0.06-0.03,0.13-0.04,0.2c-0.02,0.13-0.02,0.27-0.02,0.4c0.01,0.07,0.03,0.13,0.04,0.2
          c0.02,0.07,0.04,0.13,0.07,0.19c0.03,0.06,0.06,0.12,0.09,0.18c0.03,0.06,0.08,0.11,0.11,0.17c0.04,0.05,0.09,0.09,0.14,0.14
          c0.32,0.35,0.81,0.52,1.28,0.44c0.07-0.01,0.14-0.02,0.2-0.04c0.06-0.02,0.13-0.04,0.19-0.07c0.12-0.06,0.24-0.12,0.35-0.2
          c0.31-0.26,0.53-0.61,0.59-1.02c0.01-0.13,0.01-0.27,0-0.4C32.48,75.75,32.44,75.62,32.4,75.5z"
      />
      <path
        d="M125.4,75.5c-0.04-0.13-0.1-0.25-0.19-0.35c-0.12-0.17-0.28-0.29-0.45-0.39c-0.06-0.03-0.12-0.07-0.18-0.09
          c-0.06-0.03-0.13-0.04-0.19-0.06c-0.07-0.02-0.13-0.04-0.19-0.05c-0.2,0-0.41,0-0.6,0.05c-0.33,0.09-0.61,0.29-0.81,0.56
          c-0.27,0.29-0.34,0.74-0.32,1.13c0.01,0.07,0.02,0.14,0.04,0.2c0.02,0.07,0.05,0.12,0.08,0.19c0.02,0.06,0.04,0.13,0.08,0.19
          c0.03,0.06,0.09,0.1,0.13,0.16c0.31,0.45,0.88,0.66,1.41,0.6c0.13-0.02,0.26-0.06,0.39-0.11c0.19-0.06,0.35-0.2,0.5-0.33
          c0.1-0.09,0.17-0.2,0.23-0.33c0.18-0.29,0.24-0.63,0.21-0.96c-0.01-0.07-0.04-0.13-0.06-0.19C125.44,75.62,125.42,75.56,125.4,75.5
          z"
      />
      <path
        d="M42.63,77.4c-0.22-0.04-0.26-0.05-0.45-0.14c-0.25-0.07-0.45-0.15-0.69-0.16c-0.24-0.08-0.42-0.16-0.67-0.22
          c-0.17-0.02-0.29-0.03-0.46-0.1c-0.11-0.03-0.11-0.12-0.22-0.07c-0.1,0.05-0.08,0.09-0.1,0.2c-0.02,0.24-0.14,0.41-0.19,0.65
          c-0.1,0.41-0.27,0.66-0.37,1.07c-0.04,0.11-0.09,0.1-0.07,0.21c0.04,0.17,0.18,0.23,0.28,0.37c0.08,0.09,0.06,0.11,0.15,0.18
          c0.51-0.13,1.3,0.04,1.44,0.63c-0.06,0.35-0.11,0.59-0.5,0.67c-0.55,0.07-1.11-0.32-1.36-0.79c-0.17,0.05-0.42,0.29-0.58,0.4
          c-0.1,0.07-0.13,0.03-0.2,0.12c0.25,0.49,0.91,1.02,1.46,1.11c0.16,0.05,0.31,0.09,0.47,0.11c0.08,0.01,0.16-0.02,0.24-0.03
          c0.17,0.01,0.32,0,0.48-0.07c0.28-0.16,0.56-0.34,0.74-0.61c0.25-0.47,0.34-1.05,0.1-1.55c-0.13-0.28-0.37-0.5-0.66-0.58
          c-0.17-0.13-0.4-0.19-0.61-0.23c-0.14-0.01-0.26-0.02-0.4-0.04c0.03-0.18,0.13-0.3,0.18-0.5c0.04-0.12-0.04-0.19,0.08-0.25
          c0.11-0.06,0.11,0.05,0.23,0.09c0.28,0.03,0.21,0,0.47,0.12c0.33,0.06,0.62,0.21,0.95,0.24c0.12,0.03,0.13,0.16,0.24,0.1
          c0.1-0.05,0.01-0.15,0.12-0.44c0.04-0.17,0.17-0.34,0.1-0.42C42.79,77.37,42.75,77.44,42.63,77.4z"
      />
      <path
        d="M46.97,78.52c-0.22-0.09-0.44-0.14-0.69-0.17c-0.27-0.12-0.16-0.09-0.46-0.12c-0.51-0.17-1.06-0.31-1.6-0.4
          c-0.11-0.03-0.13-0.12-0.23-0.07c-0.1,0.05-0.04,0.1-0.04,0.21c0,0.11-0.05,0.1-0.07,0.21c-0.03,0.11-0.03,0.11-0.05,0.21
          c-0.03,0.11-0.09,0.12-0.03,0.21c0.07,0.1,0.11,0.04,0.22,0.07c0.33,0.1,0.61,0.14,0.93,0.22c0.25,0.04,0.47,0.09,0.69,0.18
          c0.12,0.03,0.19-0.06,0.23,0.06c0.03,0.11-0.08,0.08-0.16,0.17c-0.12,0.13-0.2,0.22-0.31,0.36c-0.13,0.11-0.22,0.18-0.34,0.32
          c-0.2,0.33-0.52,0.58-0.8,0.87c-0.13,0.13-0.2,0.22-0.31,0.36c-0.17,0.14-0.2,0.17-0.31,0.35c-0.16,0.13-0.2,0.17-0.32,0.34
          c-0.2,0.2-0.37,0.24-0.32,0.34c0.03,0.11,0.11,0.02,0.22,0.05c0.11,0.03,0.11,0.03,0.22,0.06c0.16,0.05,0.27,0.08,0.44,0.11
          c0.11,0.03,0.11,0.08,0.22,0.04c0.11-0.04,0.08-0.08,0.16-0.16c0.11-0.15,0.2-0.23,0.34-0.34c0.22-0.34,0.54-0.59,0.81-0.89
          c0.22-0.25,0.42-0.48,0.62-0.74c0.14-0.12,0.22-0.21,0.33-0.35c0.13-0.12,0.22-0.21,0.33-0.35c0.19-0.18,0.38-0.33,0.39-0.6
          c0.03-0.12,0.02-0.12,0.05-0.25c0.03-0.12,0.11-0.13,0.05-0.24C47.13,78.46,47.08,78.54,46.97,78.52z"
      />
      <path
        d="M50.23,79.59c-0.34-0.56-1.21-0.85-1.72-0.34c-0.32,0.17-0.41,0.52-0.44,0.85c0.06,0.24,0.1,0.5,0.29,0.68
          c0.16,0.18,0.38,0.32,0.62,0.37c0.08,0.02,0.16,0.04,0.24,0.04c0.08,0,0.17,0.01,0.25-0.01c0.17-0.04,0.31-0.13,0.43-0.25
          c0.14-0.09,0.26-0.22,0.34-0.37C50.4,80.26,50.38,79.89,50.23,79.59z M49.79,80.21c-0.06,0.23-0.21,0.46-0.47,0.47
          c-0.23,0.02-0.52-0.02-0.61-0.27c-0.04-0.07-0.1-0.13-0.11-0.21C48.55,79.17,49.97,79.25,49.79,80.21z"
      />
      <path
        d="M55.19,81.34c-0.03-0.38-0.25-0.75-0.52-1.02c-0.05-0.06-0.12-0.09-0.18-0.14c-0.24-0.21-0.52-0.36-0.84-0.36
          c-0.08-0.02-0.15-0.03-0.23-0.03c-0.39-0.02-0.79,0.04-1.13,0.24c-0.3,0.24-0.6,0.52-0.76,0.88c-0.39,0.67-0.52,1.76-0.14,2.46
          c0.28,0.6,0.76,0.97,1.4,1.11c0.08,0.01,0.16,0.03,0.23,0.03c0.24-0.01,0.48,0.01,0.7-0.09c0.64-0.24,1.24-0.8,1.37-1.49
          c0.13-0.2,0.15-0.44,0.15-0.68C55.32,81.95,55.31,81.63,55.19,81.34z M54.22,82.07c-0.03,0.62-0.3,1.42-1,1.51
          c-0.08,0.01-0.16,0.03-0.25,0.01c-0.42-0.08-0.7-0.49-0.73-0.9c-0.08-0.55,0-1.21,0.38-1.63c0.11-0.12,0.24-0.23,0.38-0.31
          c0.55-0.25,1.23,0.23,1.22,0.83C54.27,81.74,54.26,81.9,54.22,82.07z"
      />
      <path
        d="M59.53,80.84c-0.12-0.02-0.12-0.02-0.23-0.04c-0.31-0.11-0.62-0.09-0.95-0.13c-0.44-0.16-0.94-0.13-1.41-0.23
          c-0.12-0.02-0.13-0.08-0.23-0.01c-0.09,0.06-0.04,0.09-0.06,0.2c-0.02,0.16-0.03,0.27-0.05,0.43c-0.02,0.11-0.09,0.13-0.02,0.21
          c0.07,0.11,0.22-0.02,0.44,0.05c0.16,0.04,0.27,0.07,0.44,0.1c0.22-0.01,0.22-0.02,0.45,0.04c0.11,0.02,0.11,0.04,0.22,0.06
          c0.11,0.02,0.21-0.1,0.24,0.01c0.03,0.11-0.09,0.09-0.18,0.15c-0.34,0.24-0.29,0.12-0.53,0.4c-0.16,0.14-0.52,0.27-0.56,0.38
          c-0.05,0.12,0.06,0.09,0.08,0.44c0.01,0.11-0.06,0.13,0.01,0.21c0.07,0.08,0.09,0.02,0.2,0.03c0.28,0.09,0.65,0.07,0.86,0.31
          c0.33,0.27,0.06,0.87-0.34,0.92c-0.52,0-1.03-0.25-1.26-0.74c-0.15,0.05-0.25,0.22-0.37,0.31c-0.15,0.09-0.29,0.12-0.41,0.25
          c0.08,0.14,0.18,0.25,0.3,0.35c0.25,0.27,0.57,0.51,0.93,0.6c0.28,0.11,0.6,0.07,0.89,0.12c0.59-0.07,1.25-0.41,1.32-1.06
          c0.12-0.4,0.11-0.55,0-0.95c-0.19-0.38-0.6-0.57-0.98-0.73c0.08-0.11,0.15-0.04,0.38-0.26c0.19-0.16,0.38-0.23,0.56-0.39
          c0.23-0.17,0.43-0.19,0.41-0.51c0.02-0.12,0.01-0.13,0.03-0.25c0.02-0.12,0.1-0.14,0.03-0.24C59.69,80.76,59.65,80.85,59.53,80.84z
          "
      />
      <path
        d="M61.93,81.12c-0.35-0.11-0.26,0-0.63-0.07c-0.11-0.01-0.14-0.13-0.22-0.05c-0.09,0.09,0.01,0.14-0.01,0.27
          c-0.05,0.19-0.07,0.31-0.09,0.52c-0.02,0.13-0.1,0.15-0.03,0.27c0.05,0.09,0.11-0.01,0.21,0c0.1,0.01,0.12-0.02,0.21,0.03
          c0.02,0.3-0.21,0.47-0.46,0.57c-0.08,0.02-0.17,0.01-0.27,0.01c-0.04,0.1,0,0.11,0.01,0.21c0.01,0.11-0.03,0.12,0.02,0.22
          c0.17,0,0.35-0.01,0.51-0.04c0.36-0.02,0.63-0.22,0.74-0.57c0.07-0.15,0.1-0.31,0.11-0.48c0.05-0.22,0.09-0.43,0.09-0.66
          c0.01-0.11,0.1-0.13,0.03-0.22C62.08,81.05,62.03,81.13,61.93,81.12z"
      />
      <path
        d="M66.22,81.56c-0.32-0.03-0.62-0.05-0.93-0.1c-0.18,0-0.29-0.01-0.47-0.04c-0.18,0-0.3-0.03-0.46-0.07
          c-0.22,0.02-0.24,0.02-0.47-0.02c-0.12-0.01-0.14-0.09-0.23-0.02c-0.09,0.07-0.05,0.1-0.04,0.22c-0.02,0.23-0.1,0.43-0.1,0.67
          c-0.05,0.17-0.06,0.28-0.06,0.45c-0.06,0.23-0.07,0.44-0.12,0.67c-0.02,0.11-0.07,0.12-0.03,0.23c0.04,0.11,0.11,0.05,0.2,0.13
          c0.12,0.12,0.19,0.23,0.35,0.3c0.47-0.18,1.3-0.16,1.52,0.38c0.04,0.29-0.06,0.66-0.37,0.76c-0.14,0.02-0.3,0.03-0.45,0.05
          c-0.08-0.01-0.16-0.04-0.24-0.07c-0.31-0.1-0.59-0.27-0.8-0.52c-0.11,0.05-0.1,0.06-0.18,0.15c-0.14,0.11-0.22,0.2-0.33,0.34
          c-0.09,0.08-0.1,0.07-0.15,0.17c0.05,0.06,0.1,0.1,0.15,0.16c0.27,0.29,0.63,0.49,1,0.62c0.54,0.08,1.17,0.17,1.63-0.17
          c0.47-0.16,0.66-0.72,0.7-1.19c0.11-0.37-0.05-0.76-0.23-1.07c-0.26-0.28-0.61-0.44-0.98-0.52c-0.07-0.01-0.15,0-0.23-0.01
          c-0.21-0.05-0.39-0.06-0.59,0c-0.01-0.19,0.06-0.32,0.09-0.52c0.02-0.13-0.08-0.19,0.02-0.27c0.09-0.08,0.13,0.03,0.25,0.04
          c0.34-0.03,0.39,0.03,0.73,0.03c0.18,0.04,0.3,0.05,0.49,0.05c0.12,0.01,0.12,0.04,0.24,0.06c0.12,0.01,0.14,0.07,0.23,0
          c0.09-0.07,0.01-0.1,0.02-0.21c0.03-0.16,0.05-0.27,0.05-0.44c0.01-0.11,0.08-0.13,0.01-0.21C66.37,81.5,66.34,81.57,66.22,81.56z"
      />
      <path
        d="M70.37,81.84c-0.23-0.03-0.41-0.01-0.64-0.01c-0.11-0.01-0.15-0.13-0.22-0.05c-0.09,0.1,0.01,0.14,0.01,0.28
          c-0.01,0.13-0.01,0.13-0.02,0.26c-0.01,0.38-0.13,0.43-0.06,0.53c0.06,0.1,0.18-0.09,0.43,0.01c0.05,0.39-0.3,0.63-0.67,0.61
          c-0.03,0.1,0.01,0.1,0.03,0.2c0.01,0.1-0.02,0.11,0.03,0.21c0.09,0,0.16,0,0.24,0c0.34-0.02,0.67-0.13,0.86-0.42
          c0.16-0.36,0.2-0.77,0.22-1.16c0-0.27,0.09-0.37,0.01-0.44C70.52,81.78,70.48,81.85,70.37,81.84z"
      />
      <path
        d="M68.71,81.73c-0.23-0.03-0.42-0.01-0.64-0.01c-0.11-0.01-0.15-0.13-0.22-0.05c-0.09,0.1,0.01,0.14,0,0.28
          c-0.01,0.13-0.01,0.13-0.02,0.26c-0.01,0.37-0.13,0.43-0.05,0.53c0.05,0.1,0.19-0.09,0.43,0.01c0.05,0.39-0.31,0.63-0.67,0.61
          c-0.03,0.1,0.01,0.1,0.03,0.2c0.01,0.11-0.02,0.11,0.03,0.21c0.09,0,0.16,0,0.24,0c0.34-0.01,0.67-0.13,0.86-0.42
          c0.16-0.36,0.2-0.77,0.22-1.16c0-0.28,0.09-0.37,0.01-0.44C68.86,81.67,68.82,81.74,68.71,81.73z"
      />
      <path
        d="M75.8,82.06c-0.19,0-0.31-0.01-0.5-0.03c-0.12,0-0.16-0.08-0.25,0c-0.09,0.08,0,0.12,0,0.24
          c0.01,0.18,0.01,0.3-0.02,0.47c-0.02,0.32-0.02,0.62-0.02,0.94c0.05,0.46-0.08,0.25-0.02,0.71c0,0.12-0.01,0.12-0.02,0.23
          c0,0.12,0.11,0.2,0,0.23c-0.11,0.03-0.08-0.09-0.15-0.18c-0.11-0.14-0.17-0.24-0.27-0.39c-0.1-0.15-0.17-0.24-0.29-0.37
          c-0.08-0.15-0.13-0.26-0.25-0.41c-0.14-0.15-0.18-0.2-0.27-0.38c-0.16-0.19-0.28-0.37-0.42-0.57c-0.11-0.15-0.19-0.23-0.3-0.37
          c-0.07-0.09-0.02-0.14-0.12-0.2c-0.1-0.05-0.11,0.01-0.23,0.01c-0.17-0.03-0.29-0.04-0.46-0.03c-0.12,0-0.14-0.09-0.22-0.01
          c-0.09,0.07,0,0.17-0.03,0.47c-0.02,0.18-0.02,0.3,0,0.48c-0.02,0.18-0.03,0.3-0.02,0.48c0.01,0.19-0.01,0.31-0.04,0.48
          c0.06,0.39-0.01,0.47,0,0.96c0,0.4-0.06,0.51-0.03,0.96c0.01,0.18,0,0.3-0.02,0.48c0,0.12-0.1,0.17-0.02,0.25
          c0.09,0.09,0.14,0,0.26,0.01c0.12,0.01,0.13-0.03,0.25-0.03c0.12,0.01,0.12,0.02,0.25,0.02c0.12,0,0.16,0.1,0.25,0.02
          c0.09-0.08-0.02-0.13-0.02-0.25c0-0.12,0.02-0.12,0.03-0.24c0-0.18,0.01-0.31,0.02-0.48c-0.03-0.27-0.03-0.23,0.03-0.48
          c-0.04-0.25-0.05-0.22-0.01-0.49c0-0.18,0-0.3,0.01-0.48c0-0.12,0.01-0.12,0.02-0.24c0-0.12-0.12-0.21,0-0.25
          c0.11-0.04,0.08,0.1,0.14,0.19c0.16,0.19,0.23,0.37,0.38,0.57c0.18,0.26,0.41,0.45,0.54,0.74c0.16,0.17,0.27,0.35,0.4,0.56
          c0.11,0.13,0.19,0.21,0.28,0.36c0.12,0.16,0.25,0.48,0.4,0.55c0.13,0.07,0.26-0.01,0.42,0.02c0.23,0.01,0.37,0.1,0.43,0.02
          c0.08-0.09,0.02-0.12,0.02-0.24c0.02-0.19,0.01-0.31-0.01-0.48c0.06-0.57,0.05-1.12,0.07-1.69c0.06-0.41-0.05-0.31,0.01-0.72
          c0.07-0.7,0.01-0.39,0.02-0.97c0.02-0.32,0.11-0.41,0.03-0.48C75.96,81.97,75.93,82.06,75.8,82.06z"
      />
      <path
        d="M85.35,83.95c0-0.16-0.02-0.31-0.08-0.46c-0.08-0.47-0.5-1.06-0.9-1.31c-0.06-0.04-0.14-0.07-0.21-0.1
          c-0.21-0.11-0.43-0.16-0.67-0.19c-0.23-0.02-0.48-0.03-0.69,0.07c-0.74,0.19-1.16,0.76-1.39,1.45c-0.17,0.45-0.12,0.93-0.07,1.39
          c0.14,0.44,0.28,0.91,0.62,1.23c0.05,0.06,0.1,0.12,0.16,0.17c0.06,0.05,0.12,0.1,0.19,0.14c0.41,0.21,0.88,0.35,1.34,0.25
          c0.39-0.04,0.75-0.23,1.02-0.51c0.22-0.11,0.3-0.34,0.41-0.56c0.03-0.07,0.09-0.13,0.12-0.2c0.05-0.15,0.09-0.3,0.12-0.45
          c0.01-0.08,0.03-0.15,0.04-0.23C85.41,84.42,85.41,84.18,85.35,83.95z M84.25,84.96c-0.1,0.4-0.44,0.73-0.86,0.75
          c-1.6,0.03-1.35-3.62,0.34-2.77c0.42,0.29,0.59,0.79,0.58,1.29c-0.01,0.17,0,0.33,0.01,0.49C84.31,84.81,84.28,84.89,84.25,84.96z"
      />
      <path
        d="M88.3,85.83c-0.01-0.39,0.02-0.53-0.06-0.92c0.02-0.25,0.02-0.21-0.03-0.46c0.01-0.17,0.01-0.29-0.03-0.46
          c0.02-0.24-0.02-0.44-0.05-0.69c0.01-0.17,0-0.29-0.03-0.46c0.01-0.24,0.02-0.22-0.04-0.46c0-0.17-0.01-0.29-0.02-0.46
          c-0.01-0.11,0.08-0.16-0.01-0.24c-0.09-0.08-0.12,0.02-0.24,0.03c-0.11,0.01-0.11,0-0.23,0.01c-0.24,0.01-0.44,0.1-0.65,0.19
          c-0.24,0.05-0.4,0.17-0.64,0.24c-0.11,0.04-0.16-0.03-0.21,0.07c-0.05,0.09,0.02,0.1,0.05,0.2c0.04,0.15,0.07,0.26,0.13,0.4
          c0.03,0.1-0.02,0.16,0.08,0.2c0.13,0.05,0.12-0.06,0.25-0.1c0.13-0.04,0.14-0.01,0.27-0.05c0.13-0.04,0.13-0.15,0.25-0.08
          c0.11,0.06,0.03,0.11,0.04,0.22c0,0.25,0.03,0.46,0.05,0.72c0.03,0.41,0.03,0.78,0.06,1.2c0,0.19,0.02,0.31,0.06,0.48
          c-0.01,0.25,0.04,0.46,0.03,0.72c0.01,0.12-0.07,0.15,0.02,0.23c0.1,0.08,0.12,0.01,0.24,0.01c0.19-0.03,0.31-0.04,0.5-0.03
          c0.12-0.01,0.17,0.07,0.26-0.02C88.43,86.23,88.33,86.13,88.3,85.83z"
      />
      <path
        d="M93.17,83.84c-0.11-0.43-0.53-0.61-0.89-0.79c-0.24-0.01-0.49-0.07-0.74-0.03c-0.35,0.06-0.69,0.1-0.92,0.39
          c0.02-0.15,0.03-0.31,0.04-0.46c0.1-0.28,0.28-0.54,0.55-0.67c0.52-0.04,0.76-0.06,1.2,0.25c0.09-0.08,0.06-0.1,0.1-0.21
          c0.11-0.22,0.11-0.15,0.21-0.42c0.06-0.1,0.09-0.08,0.12-0.2c-0.28-0.25-0.67-0.27-1.02-0.39c-0.21,0.05-0.46,0.01-0.68,0.08
          c-0.17,0.01-0.32,0.05-0.47,0.12c-0.19,0.14-0.39,0.29-0.55,0.47c-0.05,0.06-0.1,0.12-0.14,0.19c-0.11,0.12-0.19,0.26-0.23,0.42
          c-0.1,0.23-0.08,0.47-0.13,0.71c-0.04,0.47,0.02,0.96,0.1,1.42c0.13,0.21,0.13,0.45,0.29,0.64c0.31,0.47,0.89,0.72,1.45,0.7
          c0.55,0.01,1.16-0.18,1.48-0.65C93.35,85,93.37,84.36,93.17,83.84z M92.18,84.89c-0.15,0.18-0.34,0.33-0.59,0.31
          c-0.17,0.04-0.32,0.03-0.46-0.05c-0.07-0.03-0.14-0.08-0.19-0.13c-0.06-0.05-0.11-0.11-0.14-0.18c-0.19-0.28,0-0.63,0.2-0.84
          c0.19-0.1,0.47-0.12,0.69-0.14c0.15,0.05,0.31,0.11,0.43,0.19c0.06,0.05,0.09,0.13,0.13,0.2C92.34,84.45,92.37,84.73,92.18,84.89z"
      />
      <path
        d="M96.22,81.42c-0.49-0.66-1.47-0.65-1.91,0.05c-0.5,0.67,0.09,1.57,0.84,1.68c0.44,0.06,0.8-0.24,1.08-0.54
          c0.07-0.15,0.12-0.31,0.15-0.48C96.46,81.89,96.33,81.65,96.22,81.42z M95.84,82.2c-0.03,0.16-0.12,0.3-0.27,0.38
          c-1.09,0.46-1.27-1.31-0.15-1.11C95.74,81.52,95.97,81.9,95.84,82.2z"
      />
      <path
        d="M101.08,83.18c-0.02-0.13,0.04-0.16-0.05-0.25c-0.06-0.08-0.15,0.01-0.39,0.06c-0.1,0.02-0.14,0.1-0.22,0.04
          c-0.09-0.08,0.01-0.14-0.01-0.25c-0.08-0.3-0.12-0.61-0.14-0.93c-0.04-0.17-0.07-0.28-0.1-0.46c-0.02-0.17-0.03-0.29-0.08-0.46
          c-0.01-0.17-0.03-0.29-0.07-0.46c-0.02-0.11,0.05-0.15-0.04-0.23c-0.08-0.07-0.1-0.01-0.2,0.03c-0.16,0.04-0.21,0.01-0.42,0.07
          c-0.1,0.02-0.13-0.05-0.22,0.01c-0.1,0.07-0.07,0.1-0.13,0.21c-0.1,0.16-0.15,0.27-0.23,0.42c-0.15,0.2-0.26,0.39-0.41,0.6
          c-0.14,0.22-0.19,0.42-0.36,0.63c-0.13,0.22-0.28,0.4-0.37,0.62c-0.13,0.15-0.18,0.2-0.28,0.4c-0.16,0.29-0.36,0.33-0.15,0.64
          c0.1,0.24,0.06,0.58,0.43,0.43c0.27-0.04,0.46-0.13,0.73-0.14c0.26-0.05,0.49-0.1,0.74-0.09c0.12-0.02,0.12-0.02,0.24-0.04
          c0.12-0.02,0.13-0.11,0.23-0.03c0.1,0.07,0.05,0.1,0.07,0.22c0.02,0.12-0.01,0.13,0.01,0.25c0.06,0.28-0.01,0.43,0.09,0.49
          c0.1,0.07,0.12-0.02,0.24-0.04c0.12-0.02,0.12-0.01,0.24-0.03c0.19-0.04,0.42,0.01,0.46-0.09c0.07-0.1-0.03-0.11-0.05-0.23
          c-0.03-0.19-0.04-0.31-0.05-0.49c-0.02-0.12-0.1-0.14-0.04-0.24c0.05-0.09,0.09-0.04,0.19-0.06c0.1-0.02,0.1-0.01,0.2-0.03
          c0.1-0.02,0.16,0.08,0.2-0.01C101.23,83.62,101.12,83.47,101.08,83.18z M99.49,83.16c-0.07,0.1-0.13,0.03-0.25,0.05
          c-0.12,0.02-0.12-0.02-0.25,0c-0.17,0.05-0.29,0.07-0.48,0.1c-0.12,0.02-0.17,0.13-0.24,0.03c-0.07-0.09,0.08-0.09,0.14-0.19
          c0.06-0.1,0.04-0.11,0.1-0.21c0.12-0.22,0.25-0.38,0.36-0.6c0.1-0.14,0.17-0.24,0.25-0.4c0.06-0.1,0.01-0.23,0.13-0.21
          c0.12,0.03,0.05,0.11,0.04,0.24c-0.01,0.18,0.05,0.29,0.08,0.47c0.05,0.2,0.06,0.27,0.06,0.47C99.45,83.04,99.56,83.07,99.49,83.16
          z"
      />
      <path
        d="M102.81,80.42c-0.02-0.17-0.05-0.28-0.1-0.43c-0.02-0.11,0.07-0.17-0.02-0.23c-0.1-0.07-0.22,0.05-0.53,0.09
          c-0.13,0.02-0.13,0.02-0.26,0.04c-0.13,0.02-0.2-0.05-0.27,0.06c-0.08,0.11,0.04,0.14,0.07,0.27c0.02,0.19,0.04,0.32,0.07,0.52
          c0.02,0.13-0.03,0.17,0.07,0.25c0.07,0.08,0.22-0.11,0.4-0.06c0.1,0.27,0,0.54-0.27,0.63c-0.07,0.04-0.13,0.08-0.22,0.11
          c-0.01,0.11,0.03,0.09,0.07,0.19c0.04,0.1,0,0.12,0.07,0.2c0.16-0.06,0.32-0.1,0.48-0.14c0.28-0.18,0.54-0.44,0.52-0.8
          C102.91,80.89,102.84,80.65,102.81,80.42z"
      />
      <path
        d="M107.73,81.84c-0.02-0.23-0.08-0.46-0.24-0.62c-0.07-0.15-0.17-0.25-0.31-0.31c-0.17-0.17-0.41-0.18-0.63-0.24
          c-0.42,0.04-0.89,0.03-1.26,0.28c-0.07-0.18-0.04-0.33-0.07-0.53c-0.02-0.13-0.1-0.14-0.03-0.25c0.07-0.1,0.11-0.02,0.23-0.04
          c0.12-0.03,0.12-0.04,0.24-0.07c0.33-0.04,0.61-0.17,0.95-0.22c0.12-0.02,0.12-0.03,0.24-0.06c0.12-0.03,0.17,0.07,0.25-0.03
          c0.06-0.09-0.04-0.11-0.06-0.22c-0.02-0.17-0.05-0.27-0.1-0.43c-0.02-0.11,0.07-0.18-0.02-0.23c-0.1-0.06-0.12,0.06-0.23,0.09
          c-0.25,0.03-0.45,0.11-0.69,0.13c-0.17,0.05-0.29,0.07-0.46,0.08c-0.31,0.09-0.61,0.13-0.91,0.23c-0.33,0.07-0.41-0.02-0.47,0.07
          c-0.07,0.09,0.02,0.11,0.07,0.22c0.04,0.11-0.02,0.12,0,0.23c0.04,0.16,0.07,0.28,0.11,0.44c0,0.25-0.01,0.21,0.07,0.45
          c0,0.17,0,0.28,0.03,0.45c0.02,0.11,0.06,0.1,0.08,0.21c0.02,0.11-0.07,0.15,0.01,0.23c0.07,0.09,0.11,0.05,0.22,0.09
          c0.25,0.06,0.28,0.12,0.44,0.16c0.2-0.17,0.46-0.26,0.71-0.35c0.08-0.02,0.16-0.02,0.23-0.03c0.23,0.01,0.49,0.06,0.62,0.27
          c0.08,0.2,0.16,0.47,0.04,0.67c-0.04,0.06-0.09,0.13-0.15,0.18c-0.41,0.25-0.96,0.27-1.37,0c-0.07-0.03-0.15-0.05-0.23-0.09
          c-0.12,0.12-0.13,0.27-0.21,0.42c-0.08,0.16-0.19,0.25-0.24,0.41c0.19,0.16,0.43,0.2,0.66,0.29c0.08,0.02,0.15,0.05,0.23,0.06
          c0.24,0.01,0.48-0.01,0.71,0.01c0.61-0.08,1.33-0.41,1.52-1.04C107.84,82.48,107.83,82.17,107.73,81.84z"
      />
      <path
        d="M111.11,78.75c-0.02-0.2-0.03-0.24-0.11-0.43c-0.06-0.26,0-0.4-0.09-0.45c-0.09-0.06-0.11,0.05-0.22,0.07
          c-0.16,0.04-0.26,0.07-0.41,0.12c-0.1,0.02-0.1,0.02-0.21,0.05c-0.1,0.03-0.16-0.06-0.21,0.04c-0.05,0.12,0.04,0.12,0.07,0.25
          c0.05,0.19,0.08,0.32,0.13,0.51c0.03,0.13-0.05,0.19,0.05,0.27c0.08,0.06,0.11-0.02,0.21-0.05c0.1-0.02,0.09-0.09,0.2-0.08
          c0.12,0.36-0.13,0.67-0.47,0.8c0,0.11,0.05,0.09,0.1,0.19c0.05,0.1,0.02,0.11,0.09,0.19C110.95,80.11,111.38,79.42,111.11,78.75z"
      />
      <path
        d="M109.53,79.15c-0.1-0.21-0.1-0.41-0.16-0.64c-0.03-0.11,0.02-0.16-0.08-0.21c-0.1-0.06-0.18,0.07-0.51,0.15
          c-0.13,0.03-0.13,0.03-0.26,0.06c-0.13,0.03-0.19-0.06-0.26,0.05c-0.07,0.11,0.04,0.12,0.07,0.25c0.05,0.19,0.08,0.32,0.13,0.51
          c0.03,0.13-0.05,0.18,0.05,0.26c0.08,0.06,0.11-0.02,0.21-0.05c0.1-0.03,0.09-0.09,0.2-0.08c0.03,0.09,0.02,0.18,0.02,0.26
          c-0.01,0.09-0.03,0.16-0.08,0.23c-0.09,0.14-0.23,0.24-0.4,0.3c0,0.11,0.05,0.09,0.09,0.19c0.05,0.1,0.01,0.11,0.09,0.19
          c0.42-0.07,0.76-0.36,0.91-0.74C109.62,79.65,109.54,79.4,109.53,79.15z"
      />
      <path
        d="M116.74,80.52c-0.03-0.11-0.03-0.11-0.06-0.22c-0.03-0.11,0.04-0.16-0.06-0.21c-0.1-0.05-0.1,0.04-0.21,0.08
          c-0.44,0.07-0.86,0.25-1.31,0.36c-0.17,0.08-0.24,0.11-0.43,0.14c-0.11,0.03-0.11,0.01-0.22,0.04c-0.11,0.03-0.11,0.13-0.21,0.07
          c-0.1-0.06-0.02-0.11-0.06-0.23c-0.04-0.18-0.09-0.29-0.16-0.46c-0.03-0.11-0.12-0.13-0.06-0.23c0.06-0.11,0.11-0.06,0.22-0.09
          c0.18-0.06,0.3-0.08,0.47-0.11c0.37-0.17,0.77-0.19,1.15-0.33c0.11-0.03,0.18,0.04,0.24-0.07c0.06-0.1-0.04-0.12-0.07-0.23
          c-0.04-0.17-0.08-0.27-0.15-0.42c-0.03-0.11,0.06-0.18-0.04-0.23c-0.11-0.05-0.12,0.07-0.23,0.1c-0.29,0.04-0.2,0.01-0.46,0.14
          c-0.18,0.06-0.3,0.08-0.47,0.09c-0.17,0.06-0.29,0.09-0.46,0.14c-0.32,0.1-0.37,0.22-0.46,0.16c-0.1-0.05-0.02-0.13-0.06-0.24
          c-0.05-0.17-0.08-0.28-0.12-0.45c-0.03-0.11-0.14-0.13-0.08-0.23c0.06-0.11,0.13-0.02,0.25-0.06c0.12-0.03,0.12-0.04,0.23-0.07
          c0.3-0.08,0.41-0.17,0.71-0.2c0.28-0.07,0.43-0.18,0.7-0.22c0.28-0.08,0.44-0.03,0.48-0.13c0.06-0.1-0.05-0.11-0.09-0.22
          c-0.06-0.16-0.08-0.27-0.12-0.43c-0.03-0.11,0.04-0.16-0.06-0.21c-0.08-0.06-0.16,0.06-0.44,0.13c-0.17,0.03-0.28,0.07-0.44,0.13
          c-0.23,0.04-0.24,0.05-0.44,0.15c-0.23,0.04-0.44,0.1-0.67,0.18c-0.23,0.08-0.43,0.11-0.67,0.18c-0.17,0.04-0.28,0.09-0.43,0.16
          c-0.11,0.03-0.16-0.05-0.21,0.05c-0.05,0.11,0,0.11,0.04,0.23c0.06,0.17,0.1,0.28,0.14,0.46c0.07,0.17,0.1,0.29,0.13,0.46
          c0.07,0.16,0.11,0.28,0.16,0.46c0.03,0.33,0.19,0.6,0.27,0.93c0.07,0.4,0.28,0.76,0.32,1.16c0.06,0.17,0.11,0.28,0.17,0.45
          c0.03,0.11-0.06,0.18,0.05,0.24c0.1,0.06,0.12,0.01,0.24-0.05c0.15-0.07,0.28-0.07,0.44-0.14c0.24-0.06,0.44-0.13,0.67-0.18
          c0.3-0.16,0.33-0.11,0.66-0.24c0.38-0.12,0.76-0.15,1.12-0.32c0.11-0.03,0.18,0.05,0.24-0.05
          C116.94,80.87,116.82,80.77,116.74,80.52z"
      />
    </svg>
  )
}
