import { forwardRef } from 'react'
import clsx from 'clsx'

const OuterContainer = forwardRef(function OuterContainer(
  { className, children, ...props },
  ref
) {
  return (
    <div ref={ref} className={clsx('sm:px-8', className)} {...props}>
      <div className="h-full max-w-none lg:px-4">{children}</div>
    </div>
  )
})

const InnerContainer = forwardRef(function InnerContainer(
  { className, children, ...props },
  ref
) {
  return (
    <div
      ref={ref}
      className={clsx('relative h-full px-4 sm:px-8 lg:px-4', className)}
      {...props}
    >
      <div className="h-full max-w-none">{children}</div>
    </div>
  )
})

export const HeaderContainer = forwardRef(function Container(
  { children, ...props },
  ref
) {
  return (
    <OuterContainer ref={ref} {...props}>
      <InnerContainer>{children}</InnerContainer>
    </OuterContainer>
  )
})

HeaderContainer.Outer = OuterContainer
HeaderContainer.Inner = InnerContainer
