import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Image from 'next/image'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import MediaPlayer from './mediaviewer/MediaPlayer'

function debounce(fn, ms) {
  let timer
  return (_) => {
    clearTimeout(timer)
    timer = setTimeout((_) => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}
export default function MediaViewer({ handleMediaViewer, mediaViewerData }) {
  const [expand, setExpand] = useState(false)

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 1000)

    window.addEventListener('resize', debouncedHandleResize)

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  const imageIndex = mediaViewerData.index

  const [currentSlide, setCurrentSlide] = useState(0)

  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: imageIndex,
    resize: dimensions.width,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })

  const closeMediaViewer = () => {
    handleMediaViewer(null)
  }

  useEffect(() => {
    setTimeout(() => {
      setExpand(true)
    }, '0')
  }, [])

  return (
    <Transition.Root
      show={true}
      appear
      as={Fragment}
      enter="ease-out duration-[700ms]"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-[300ms]"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog
        as="div"
        className="relative z-[20000]"
        // initialFocus={cancelButtonRef}
        onClose={() => closeMediaViewer()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-dammanBg transition-opacity dark:bg-dammanBgDark" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto ">
          <div className="flex min-h-full min-w-full items-center justify-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 "
            >
              <Dialog.Panel
                className={`absolute inset-0 m-0 flex w-full items-center 
                 justify-center`}
              >
                <div
                  className="navigation-wrapper transition-all duration-700"
                  style={{
                    width: 'calc(100vw - (30px))',
                    height: 'calc(100vh - (30px))',
                  }}
                >
                  <div
                    className={
                      'absolute inset-0 z-50 mx-auto flex h-10 items-start justify-between p-4'
                    }
                  >
                    <div className="flex flex-1 justify-end pr-2 font-dmsans text-sm text-dammanBgDark">
                      <button type="button" onClick={() => closeMediaViewer()}>
                        CLOSE
                      </button>
                    </div>
                  </div>
                </div>
                <div className="absolute inset-0 m-auto h-[56.25vw] max-h-[90vh] w-[80vw] max-w-[147.78vh] overflow-hidden ">
                  {expand && (
                    <div ref={sliderRef} className="keen-slider h-full w-full">
                      {mediaViewerData.ImageData.map((src, idx) => (
                        <div
                          key={idx}
                          className="keen-slider__slide absolute top-0 h-full w-full "
                        >
                          <div
                            className="absolute left-0 top-0 w-full  "
                            style={{ height: '100%' }}
                          >
                            {src.youtubeUrl !== null ||
                            src.mediaItem.data.attributes.mime ===
                              'video/mp4' ? (
                              <MediaPlayer item={src} />
                            ) : (
                              <>
                                <Image
                                  alt={
                                    src.mediaItem?.data
                                      ? src.mediaItem.data.attributes
                                          .alternativeText
                                      : src.videoPosterImage.data.attributes
                                          .alternativeText
                                  }
                                  fill
                                  src={
                                    src.mediaItem?.data
                                      ? src.mediaItem.data.attributes.url
                                      : src.videoPosterImage.data.attributes.url
                                  }
                                  style={{ objectFit: 'cover' }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div
                  className="absolute inset-0 m-auto flex max-h-[90vh] w-[100vw] max-w-[200vh] flex-row justify-between  px-10 "
                  style={{ height: '15%' }}
                >
                  <div className=" flex h-full flex-col items-center justify-center">
                    {loaded && instanceRef.current && (
                      <Arrow
                        left
                        onClick={(e) =>
                          e.stopPropagation() || instanceRef.current?.prev()
                        }
                        disabled={currentSlide === 0}
                      />
                    )}
                  </div>
                  <div className=" flex h-full flex-col items-center justify-center">
                    {loaded && instanceRef.current && (
                      <Arrow
                        onClick={(e) =>
                          e.stopPropagation() || instanceRef.current?.next()
                        }
                        disabled={
                          currentSlide ===
                          instanceRef.current.track.details.slides.length - 1
                        }
                      />
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function Arrow(props) {
  return (
    <button
      onClick={props.onClick}
      type="button"
      className={`${
        props.disabled
          ? ' pointer-events-none text-gray-300 '
          : 'pointer-events-auto text-dammanText '
      } flex h-16 w-16 items-center justify-center rounded-full p-2.5 text-center text-sm font-medium transition-all duration-200 ease-in-out hover:bg-dammanBgHover hover:text-dammanText  focus:outline-none focus:ring-2 focus:ring-dammanBgDark  dark:text-white dark:focus:ring-blue-800 `}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1}
        stroke="currentColor"
      >
        {props.left && (
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        )}

        {!props.left && (
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        )}
      </svg>

      <span className="sr-only">Go to next media in slider</span>
    </button>
  )
}
