import { HeaderContainer } from '@/components/HeaderContainer'
import { NpContainer } from './NPContainer'

export default function MediaContainer({
  children,
  ratioProps = 'sm:pb-ratio_hero_Sm md:pb-ratio_hero_Md lg:pb-ratio_hero_Lg',
  innerProps = 'max-w-7xl',
  outerProps = 'mt-0',
}) {
  return (
    <>
      {/* <HeaderContainer className={`w-full ${maxWidth} ${marginTop}`}> */}
      <NpContainer
        innerProps={`${innerProps} w-full`}
        outerProps={`${outerProps} `}
      >
        <div className={`h-full w-full ${ratioProps}`}>
          <div className="absolute top-0 bottom-0 right-0 left-0 ">
            {children}
          </div>
        </div>
      </NpContainer>
      {/* </HeaderContainer> */}
    </>
  )
}
