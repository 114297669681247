import Footer from './Footer'
import NpHeader from './NpHeader'
import Seo from './Seo'
import Image from 'next/image'
import BgMap from '@/images/bg_damman.png'

export default function Layout({
  pageData,
  navigationData,
  footerNavigationData,
  isHomePage,
  children,
}) {
  return (
    <>
      <Seo seo={pageData.Seo[0]} />
      <NpHeader
        pageData={pageData}
        navigationData={navigationData}
        isHomePage={isHomePage}
      />
      <div className="sm:prose-sm lg:prose-lg prose absolute left-0 right-0 top-0 min-h-screen dark:prose-invert  ">
        <div className="absolute left-0 top-0 m-0 hidden w-1/2 max-w-3xl p-0 opacity-60 dark:opacity-30 bigScreen:flex">
          {isHomePage && <Image src={BgMap} alt={''} sizes="30vw" />}
        </div>
        <main style={{ marginTop: '0px', marginBottom: '0px' }}>
          {children}
        </main>

        <Footer
          navigationData={navigationData}
          footerNavigationData={footerNavigationData}
          locale={pageData.locale}
        />
      </div>
    </>
  )
}
