import { forwardRef, Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Link from 'next/link'
import clsx from 'clsx'

export default forwardRef(function MainNavigation(
  { navigationData, locale, open, handleClick },

  dialogHeaderParentRef
) {
  const dialogRef = useRef()

  const localeNavigation = navigationData.find((nav) => nav[locale])
  const navItems = localeNavigation[locale].renderNavigation

  return (
    <>
      <Transition
        appear
        enter="transition-opacity duration-[800ms]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-[400ms]"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={open}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="fixed inset-0 z-30 overflow-hidden "
          onClose={() => handleClick}
          ref={dialogRef}
        >
          <div className="absolute left-0 top-0 overflow-hidden">
            <div className={`absolute inset-0 bg-dammanLjusBla`} />
            <div
              ref={dialogHeaderParentRef}
              style={{
                top: '0',
                zIndex: '30',
              }}
              className="relative w-full"
            ></div>

            <div className="relative flex h-screen w-screen flex-col items-center justify-start xl:justify-center">
              <ul
                className={`mt-14 xl:mt-0 ${
                  open ? 'primaryMenuItemIn' : 'primaryMenuItemOut'
                }`}
              >
                {navItems.map((navItem, i) => {
                  return (
                    <li
                      key={`${navItem.id}-${i}`}
                      className={`flex justify-center ring-transparent transition-colors hover:bg-dammanBgHover`}
                    >
                      <Link
                        className="w-full ring-transparent"
                        href={navItem.related.attributes.slug}
                        locale={locale}
                      >
                        <span
                          className={clsx(
                            'mx-auto flex w-full items-center justify-center first-letter:ring-transparent',
                            'p-2 text-center font-prata font-normal text-dammanText',
                            'text-xl lg:text-xl bigScreen:text-2xl'
                          )}
                        >
                          {navItem.title}
                        </span>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
})
