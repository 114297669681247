import { NpContainer } from '../NPContainer'
import Link from 'next/link'

export default function Button({ data }) {
  const contactLink =
    data.linkPage.data !== null ? data.linkPage.data.attributes.slug : data.link
  return (
    <NpContainer
      innerProps="w-full flex flex-col items-center p-0 m-0 max-w-screen-sm"
      outerProps="w-full items-center mx-0 px-4 sm:px-8 "
    >
      <Link href={contactLink}>
        <button className="text mb-2 bg-dammanMorktBlaGra py-2 px-5 font-raleway text-md font-bold tracking-wide text-dammanBg hover:bg-dammanBgDarkHover dark:bg-white dark:text-dammanBgDark  md:text-dammanLargeParagraph ">
          {data.buttonText}
        </button>
      </Link>
    </NpContainer>
  )
}
