import { NpContainer } from '../NPContainer'
import Link from 'next/link'
import clsx from 'clsx'

export default function Cta({ data }) {
  const contactLink =
    data.linkPage.data !== null ? data.linkPage.data.attributes.slug : data.link
  return (
    <NpContainer
      innerProps="w-full flex flex-col items-center p-6 sm:p-8 m-0 max-w-screen-sm border border-dammanMorktBlaGra border-solid "
      outerProps={clsx(
        'w-full items-center mx-0 p-6 sm:p-8 my-12 sm:my-20',
        data.extraClasses
      )}
    >
      <h2 className="">{data.heading ? data.heading : 'Boka'}</h2>
      <div className="mx-auto my-3 h-[1px] w-full max-w-[80px] bg-dammanText dark:bg-white sm:max-w-[140px] lg:my-4 bigScreen:block" />

      {data.text && (
        <p className="mx-8 max-w-lg text-center text-sm leading-5 sm:mx-4 sm:text-md sm:leading-7">
          {data.text}
        </p>
      )}

      <Link href={contactLink} passHref legacyBehavior>
        <button
          href={contactLink}
          className="text mt-2 bg-dammanMorktBlaGra  py-2 px-5 font-raleway text-sm text-dammanBg hover:bg-dammanBgDarkHover dark:bg-white dark:text-dammanBgDark sm:mt-4 sm:text-md"
        >
          {data.buttonText}
        </button>
      </Link>
    </NpContainer>
  )
}
