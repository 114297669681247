import { forwardRef, useState, useEffect } from 'react'

export default forwardRef(function Burger(
  { handleClick, isHomePage, open },
  buttonRef
) {
  const [openBurger, setOpenBurger] = useState(false)

  useEffect(() => {
    if (open) {
      let timer1 = setTimeout(() => setOpenBurger(true), 100)

      return () => {
        clearTimeout(timer1)
      }
    } else {
      setOpenBurger(false)
    }
  }, [open])

  return (
    <button
      ref={buttonRef}
      tabIndex="0"
      onClick={(e) => handleClick(e)}
      aria-label={openBurger ? 'Close menu' : 'Open menu'}
      className={`relative h-14 w-14 rounded-full `}
    >
      <div
        className={openBurger ? 'hamburger is-active' : 'hamburger'}
        id="hamburger-1"
      >
        <span
          className={`w-[28px] bg-[var(--textColor)] transition-colors ${
            isHomePage && `bigScreen:bg-dammanBgDark`
          } line`}
        ></span>
        <span
          className={`w-[28px] bg-[var(--textColor)] transition-colors ${
            isHomePage && `bigScreen:bg-dammanBgDark`
          } line`}
        ></span>
      </div>
    </button>
  )
})
