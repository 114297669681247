import { NpContainer } from '../NPContainer'
// import Image from 'next/future/image'
import Image from 'next/image'
import { useState, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'

const IntroMedia = ({ data }) => {
  const [parentWidth, setParentWidth] = useState(null)

  const callBackRef = useCallback((domNode) => {
    if (domNode) {
      setParentWidth(domNode.getBoundingClientRect().width)
    }
  }, [])

  const { ref, inView, entry } = useInView({
    rootMargin: '0px 0px 0px -200px',
    delay: 400,
  })

  return (
    <NpContainer
      innerProps="w-full max-w-md sm:max-w-3xl bigScreen:max-w-4xl overflow-hidden"
      outerProps="w-full items-center m-0 px-6 sm:px-8 "
      ref={callBackRef}
    >
      <div ref={ref} className={`h-full w-full pb-ratio_introMedia`}>
        <div className="absolute top-0 bottom-0 right-0 left-0 ">
          <div className="relative z-10 flex h-full w-full flex-row">
            <div className="flex h-full flex-1 " />
            <div className="flex-2 flex h-full w-2 bg-dammanBg dark:bg-dammanBgDark sm:w-5 " />
            <div className="flex h-full flex-1 " />
            <div className="flex-2 flex h-full w-2 bg-dammanBg dark:bg-dammanBgDark sm:w-5 " />
            <div className="flex h-full flex-1 " />
          </div>
          {parentWidth && (
            <Image
              sizes={`${parentWidth}px`}
              alt={data.image.data.attributes.alternativeText}
              fill
              src={data.image.data.attributes.url}
              style={
                inView
                  ? { objectFit: 'cover', transform: 'scale(1.05)' }
                  : { objectFit: 'cover', transform: 'scale(1)' }
              }
              className="absolute top-0 left-0 transition-all duration-[2500ms] ease-in-out"
            />
          )}
        </div>
      </div>
    </NpContainer>
  )
}
export default IntroMedia
