import { useState, useCallback } from 'react'
import Image from 'next/image'
import MediaContainer from '../MediaContainer'
import { NpContainer } from '../NPContainer'
import Video from '../video'
import clsx from 'clsx'
import { useInView } from 'react-intersection-observer'

export default function Media({ data }) {
  const { className = '' } = data

  const [parentWidth, setParentWidth] = useState(null)

  const { ref, inView, entry } = useInView({
    rootMargin: '0px 0px 0px -200px',
    delay: 200,
  })

  const callBackRef = useCallback((domNode) => {
    if (domNode) {
      setParentWidth(domNode.getBoundingClientRect().width)
    }
  }, [])

  const media = data.files.data[0].attributes

  return (
    <>
      <div ref={ref} className={clsx('w-full', className)}>
        <MediaContainer
          ratioProps={'pb-ratio_16_9'}
          innerProps="max-w-lg sm:max-w-3xl bigScreen:max-w-4xl overflow-hidden"
          outerProps={`w-full items-center m-0 px-6 sm:px-8 relative`}
        >
          <div ref={callBackRef} className="absolute h-full w-full  ">
            {media.mime === 'video/mp4' ? (
              <Video media={media} />
            ) : (
              <Image
                className="transition-all duration-[2500ms] ease-in-out"
                sizes={`${parentWidth + 100}px`}
                alt={media.alternativeText}
                fill
                src={media.url}
                style={
                  inView
                    ? { objectFit: 'cover', transform: 'scale(1.05)' }
                    : { objectFit: 'cover', transform: 'scale(1)' }
                }
                priority
              />
            )}
          </div>
        </MediaContainer>
        {data.description && (
          <NpContainer
            innerProps="w-full max-w-md sm:max-w-3xl bigScreen:max-w-4xl"
            outerProps="w-full items-center m-0 px-6 sm:px-8 relative top-0 mb-2"
          >
            <div className="not-prose pt-2 text-dammanText">
              {/* <h3 className=" text-sm font-black">{data.heading}</h3> */}
              <p className="text-xs font-light">{data.description}</p>
            </div>
          </NpContainer>
        )}
      </div>
    </>
  )
}
