import { NpContainer } from '../NPContainer'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState, useCallback } from 'react'
import clsx from 'clsx'
import { useInView } from 'react-intersection-observer'

const PageTeaser = ({ data }) => {
  const link = data.page?.data?.attributes
  const img = data.image?.data.attributes

  const { heading, description, buttonText, extraClasses } = data
  const router = useRouter()

  const [parentWidth, setParentWidth] = useState(null)

  const callBackRef = useCallback((domNode) => {
    if (domNode) {
      setParentWidth(domNode.getBoundingClientRect().width)
    }
  }, [])

  const { ref, inView, entry } = useInView({
    rootMargin: '300px 0px 0px 0px',
  })

  return (
    <>
      <div
        className={clsx(
          'mx-0 mb-3 flex w-full max-w-6xl flex-col items-center bg-gradient-to-t from-transparent to-dammanBgGradient px-6 pt-6 sm:px-8 sm:pt-8 lg:mb-0 lg:flex-row lg:bg-dammanMorktBlaGra lg:bg-opacity-[0.05] lg:px-0 lg:pt-0',
          extraClasses
        )}
      >
        <div className="relative mx-auto w-full max-w-screen-media px-0 sm:max-w-screen-mediaMd  ">
          <div
            ref={ref}
            className={`h-full w-full pb-ratio_4_3 xs:pb-ratio_16_9 lg:pb-ratio_1_1`}
          >
            <div
              ref={callBackRef}
              className="absolute top-0 bottom-0 right-0 left-0 overflow-hidden "
            >
              {parentWidth && (
                <Image
                  className="transition-all duration-[2000ms] ease-in-out"
                  sizes={`${parentWidth}px`}
                  alt={img.alternativeText}
                  fill
                  src={img.url}
                  style={
                    inView
                      ? { objectFit: 'cover', transform: 'scale(1.05)' }
                      : { objectFit: 'cover', transform: 'scale(1)' }
                  }
                />
              )}
            </div>
          </div>
        </div>
        <NpContainer
          innerProps="w-full max-w-xl px-0 sm:px-8 lg:px-20 flex flex-col"
          outerProps="w-full items-center m-0 px-2 xs:px-4 sm:px-8 lg:px-0 flex justify-center mb-10 "
        >
          <h2 className="mx-auto mb-2 mt-8 text-[1.48rem] xs:text-xl lg:mt-0 ">
            {heading}
          </h2>
          <div className="mx-auto my-1 h-[1px] w-full max-w-[80px] bg-dammanText dark:bg-white  sm:mb-3 sm:mt-2 md:max-w-[100px] " />

          <p className="text-center lg:text-md lg:leading-6">{description}</p>
          <div className={'flex w-full justify-center'}>
            <Link passHref href={link.slug} legacyBehavior>
              <button
                href={link.slug}
                className="border-1 mt-4 border-[1px] border-solid border-dammanText py-[0.5rem] px-4 font-raleway text-md tracking-normal no-underline transition-all duration-200 ease-in-out hover:bg-dammanBgDark hover:text-dammanBg dark:text-dammanBgDark hover:dark:bg-white sm:mt-4  "
              >
                {buttonText}
              </button>
            </Link>
          </div>
        </NpContainer>
      </div>
    </>
  )
}
export default PageTeaser
