import Link from 'next/link'
import { NpContainer } from './NPContainer'
import Logo from '@/components/svg/Dammanlogo'
import React from 'react'
import Image from 'next/image'
import Map from '@/images/map_damman_footer.jpeg'

export default function Footer({
  navigationData,
  footerNavigationData,
  locale,
}) {
  const localeNavigation = navigationData.find((nav) => nav[locale])
  const navItems = localeNavigation[locale].renderNavigation

  const localeFooterNavigation = footerNavigationData.find((nav) => nav[locale])
  const navItemsFooter = localeFooterNavigation[locale].renderNavigation

  return (
    <footer className=" relative mb-0 mt-0 bg-dammanBgDark dark:bg-dammanBg ">
      <NpContainer
        innerProps="bg-dammanBgDark dark:bg-dammanBg w-full  "
        outerProps="bg-dammanBgDark dark:bg-dammanBg h-full flex flex-col items-center py-6 sm:py-8 m-0 p-6 sm:px-8"
      >
        <div className="flex flex-col sm:flex-row sm:items-stretch sm:justify-between ">
          <div className="w-full">
            <ul className="m-0 flex list-none flex-row flex-wrap items-start justify-center p-0 sm:flex-col sm:justify-start">
              {navItems.map((navItem) => {
                return (
                  <li
                    key={navItem.related.id}
                    className="not-prose m-0 px-4 py-[0.6rem] font-raleway text-sm text-dammanBg underline no-underline underline-offset-4 dark:text-dammanText xs:py-[0.4rem] sm:px-0 sm:text-sm xl:text-md"
                  >
                    <Link
                      href={navItem.related.attributes.slug}
                      locale={locale}
                      className=""
                    >
                      {navItem.title}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="not-prose flex w-full  flex-col items-center justify-end pt-10 sm:pt-0">
            <div className="pb-4">
              <Link
                href="/"
                locale={locale}
                aria-label="Dämman logotype"
                className="pointer-events-auto"
              >
                <Logo
                  className="fill-dammanBg dark:fill-dammanBgDark"
                  style={{
                    width: '190px',
                    heigth: '114px',
                  }}
                />
              </Link>
            </div>

            <div className="not-prose relative flex h-12 items-end justify-center pb-0 text-sm text-dammanBg dark:text-dammanText sm:m-0 sm:mt-0 sm:p-0">
              {navItemsFooter.map((navItem, i, row) => {
                if (i + 1 === row.length) {
                  return (
                    <Link
                      key={navItem.id}
                      href={navItem.path}
                      locale={locale}
                      aria-label={navItem.title}
                      className="pointer-events-auto "
                    >
                      {navItem.title}
                    </Link>
                  )
                } else {
                  return (
                    <React.Fragment key={navItem.id}>
                      <Link
                        href={navItem.path}
                        locale={locale}
                        aria-label={navItem.title}
                        className="pointer-events-auto "
                      >
                        {navItem.title}
                      </Link>
                      <div className="pl-4 pr-4">|</div>
                    </React.Fragment>
                  )
                }
              })}
              <div className="pl-4 pr-4">|</div>
              <a
                href="/sitemap.xml"
                aria-label="Sitemap"
                className="pointer-events-auto"
              >
                Sitemap
              </a>
            </div>
          </div>
          <div className="relative w-full text-white ">
            <div className="absolute inset-0 hidden justify-end sm:flex">
              <Image
                alt={'Map Dämman'}
                fill
                sizes="400px"
                src={Map}
                style={{ objectFit: 'contain', objectPosition: 'right' }}
              />
            </div>
          </div>
        </div>
      </NpContainer>
    </footer>
  )
}
