import { forwardRef } from 'react'
import clsx from 'clsx'
// className="flex w-full justify-center  px-4 sm:px-12">
const OuterContainer = forwardRef(function OuterContainer(
  { children, outerProps, outerStyleProps },

  ref
) {
  return (
    <div
      ref={ref}
      className={clsx('flex w-full justify-center ', `${outerProps}`)}
      style={outerStyleProps}
    >
      {children}
    </div>
  )
})

const InnerContainer = forwardRef(function InnerContainer(
  { innerProps, children },
  ref
) {
  return (
    <div ref={ref} className={clsx('relative mx-auto px-0', innerProps)}>
      {children}
    </div>
  )
})

export const NpContainer = forwardRef(function Container(
  { children, outerProps, innerProps, outerStyleProps },
  ref
  // ref
) {
  return (
    <OuterContainer outerProps={outerProps} outerStyleProps={outerStyleProps}>
      {/* <OuterContainer ref={ref} outerProps={outerProps}></OuterContainer> */}
      <InnerContainer innerProps={innerProps} ref={ref}>
        {children}
      </InnerContainer>
    </OuterContainer>
  )
})

NpContainer.Outer = OuterContainer
NpContainer.Inner = InnerContainer
