import { useEffect, useRef, useState, useCallback } from 'react'
import LocaleSwitch from '@/components/LocaleSwitch'
import Logo from '@/components/svg/Dammanlogo'
import MainNavigation from './navigation/MainNavigation'
import Link from 'next/link'
import Burger from './navigation/burger'
import { useRouter } from 'next/router'

import { NpContainer } from './NPContainer'

function clamp(number, a, b) {
  let min = Math.min(a, b)
  let max = Math.max(a, b)
  return Math.min(Math.max(number, min), max)
}

function LightModeIcon(props) {
  return (
    <svg viewBox="0 0 12 12" {...props}>
      <path
        d="M4,5V4h0.5V2H4V1.5l2-1l2,1V2H7.5v2H8v1H7.37L4.22,6.82L4.5,5H4 M6.5,4V2h-1v2H6.5 M3.5,11.5l0.02-0.12l4.55-2.63l0.26,1.69
          L6.5,11.5H3.5 M4.03,8.09L7.66,6l0.26,1.68l-4.2,2.42C3.72,10.11,4.03,8.08,4.03,8.09z"
      />
    </svg>
  )
}

function DarkModeIcon(props) {
  return (
    <svg viewBox="0 0 12 12" {...props}>
      <path
        d="M4,5V4h0.5V2H4V1.5l2-1l2,1V2H7.5v2H8v1H7.37L4.22,6.82L4.5,5H4 M6.5,4V2h-1v2H6.5 M3.5,11.5l0.02-0.12l4.55-2.63l0.26,1.69
          L6.5,11.5H3.5 M4.03,8.09L7.66,6l0.26,1.68l-4.2,2.42C3.72,10.11,4.03,8.08,4.03,8.09z"
      />
      <polygon points="0.61,4.49 4,3 0.61,1.56 " />
      <polygon points="11.55,1.56 8.16,3.05 11.55,4.49 " />
    </svg>
  )
}

function ModeToggle() {
  function disableTransitionsTemporarily() {
    document.documentElement.classList.add('[&_*]:!transition-none')
    window.setTimeout(() => {
      document.documentElement.classList.remove('[&_*]:!transition-none')
    }, 0)
  }

  function toggleMode() {
    disableTransitionsTemporarily()

    let darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    let isSystemDarkMode = darkModeMediaQuery.matches
    let isDarkMode = document.documentElement.classList.toggle('dark')

    if (isDarkMode === isSystemDarkMode) {
      delete window.localStorage.isDarkMode
    } else {
      window.localStorage.isDarkMode = isDarkMode
    }
  }

  return (
    <button
      type="button"
      aria-label="Toggle dark mode"
      className={'h-8 w-8 '}
      onClick={toggleMode}
    >
      <LightModeIcon className="fill-white dark:hidden bigScreen:fill-[var(--headerTextColorLight)]" />
      <DarkModeIcon className="hidden fill-white dark:block bigScreen:fill-[var(--headerTextColorDark)]" />
    </button>
  )
}

export default function NpHeader({ pageData, navigationData, isHomePage }) {
  const [open, setOpen] = useState(false)

  const buttonRef = useRef()
  const headerParentRef = useRef(null)
  const dialogHeaderParentRef = useRef(null)
  const headerRef = useRef(null)

  const renderCount = useRef(0)

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const handleClick = useCallback(
    async (e) => {
      e.preventDefault()
      setOpen(!open)
    },
    [open]
  )

  const { events } = useRouter()

  const close = useCallback(async () => {
    setOpen(false)
  }, [])

  useEffect(() => {
    // subscribe to next/router event
    events.on('routeChangeComplete', close)
    return () => {
      // unsubscribe to event on unmount to prevent memory leak
      events.off('routeChangeComplete', close)
    }
  }, [close, events])

  useEffect(() => {
    function setProperty(property, value) {
      document.documentElement.style.setProperty(property, value)
    }

    function removeProperty(property) {
      document.documentElement.style.removeProperty(property)
    }
    setProperty('--logoColor', `white`)
    setProperty('--textColor', `white`)
    setProperty('--bgColor', `rgb(0,0,0,0)`) //Transperant

    async function click() {
      if (open) {
        await delay(20)
        dialogHeaderParentRef.current.appendChild(headerRef.current)
        buttonRef.current.addEventListener(
          'click',
          function buttonClicked(event) {
            handleClick(event)
          }
        )
        await delay(30)
        setProperty('--logoColor', `#3F5367`) //Dämman morkbla
        setProperty('--textColor', `#3F5367`) //Dämman morkbla
        setProperty('--textColorDark', `#3F5367`) //Dämman morkbla
      } else {
        setProperty('--bgColor', `rgb(0,0,0,0)`) //Transperant
        setProperty('--textColor', `white`)
        await delay(200)
        headerParentRef.current.appendChild(headerRef.current)
      }
    }
    click()
  }, [open, handleClick])

  return (
    <>
      <div
        ref={headerParentRef}
        style={{
          top: '0',
          zIndex: '30',
        }}
        className="relative w-full  "
      >
        <div ref={headerRef}>
          <NpContainer
            innerProps="m-0 p-0 w-full h-full"
            outerProps="h-14 sm:h-20 items-center px-4 sm:px-8"
          >
            {/* :::::::::::  FIXED HEADER ::::::::::: */}

            <div className="grid h-full w-full grid-cols-6 items-center text-dammanText">
              <div className="pointer-events-auto col-span-1 flex items-center justify-start sm:col-span-2 ">
                <LocaleSwitch
                  pageData={pageData}
                  isHomePage={isHomePage}
                  open={open}
                />
              </div>
              <div
                className={`col-span-4 flex items-center justify-center  sm:col-span-2`}
              >
                <div
                  className={`absolute top-0 fill-[white] pt-4 sm:pt-6 ${
                    isHomePage && 'bigScreen:pt-[68px]'
                  } `}
                >
                  <Link
                    href="/"
                    aria-label="Home"
                    className={`w-[100px] ${
                      isHomePage && 'bigScreen:w-[210px]'
                    } pointer-events-auto flex`}
                  >
                    <Logo
                      className={`h-full w-full fill-[var(--logoColor)] transition-all duration-1000 ${
                        isHomePage && 'bigScreen:fill-dammanBgDark'
                      }`}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-span-1 -mr-2 flex items-center justify-end sm:col-span-2 sm:mr-0">
                <Burger
                  ref={buttonRef}
                  handleClick={handleClick}
                  isHomePage={isHomePage}
                  open={open}
                />
              </div>
            </div>
          </NpContainer>
        </div>

        <MainNavigation
          ref={dialogHeaderParentRef}
          navigationData={navigationData}
          locale={pageData.locale}
          isHomePage={isHomePage}
          open={open}
          handleClick={handleClick}
        />
      </div>
    </>
  )
}
