import Link from 'next/link'

export default function LocaleSwitch({ pageData, isHomePage }) {
  const { slug: lang_slug, locale: lang_locale } =
    pageData.localizations?.data[0]?.attributes

  return (
    <div
      className={`flex flex-row items-start justify-start p-0 text-left text-sm sm:text-md leading-none text-[var(--textColor)] transition-colors duration-500 ${
        isHomePage && 'bigScreen:text-dammanBgDark'
      }`}
    >
      <Link
        href={lang_slug}
        locale={lang_locale}
        aria-label={`Change language to ${
          lang_locale === 'en' ? 'English' : 'Svenska'
        }`}
      >
        {lang_locale === 'en' ? 'English' : 'Svenska'}
      </Link>
    </div>
  )
}
