import Image from 'next/image'
import { NpContainer } from '../NPContainer'
import Obfuscate from 'react-obfuscate'

export default function Contact({ data }) {
  const media = data.files.data[0].attributes

  return (
    <>
      <NpContainer
        innerProps="flex justify-center flex-col items-center"
        outerProps={`w-full items-center m-0 px-6 sm:px-8 relative my-4`}
      >
        <div className="relative h-48 w-48">
          <Image
            className="rounded-full"
            fill
            alt={media.alternativeText}
            src={media.url}
            style={{ objectFit: 'cover' }}
          />
        </div>

        <div className="pt-4 text-center text-dammanText">
          <h3 className="">{data.name}</h3>

          <p className="mt-2">
            E-mail: <Obfuscate email={data.email} aria-label="Email" />
            <span className="mt-1 block">
              Phone: <Obfuscate tel={data.phone} aria-label="Phone" />
            </span>
          </p>
        </div>
      </NpContainer>
    </>
  )
}
