export default function Video({ media, controls = true, autoPlay = false }) {
  const poster = media.url.substr(0, media.url.lastIndexOf('.')) + '.jpg'

  return (
    <video
      controls={controls}
      autoPlay={true}
      muted
      poster={poster}
      className="fluidVideoBackground"
    >
      <source src={media.url} type={media.mime} />
    </video>
  )
}
