import { useRouter } from 'next/router'
import ErrorPage from 'next/error'
import Layout from '@/components/Layout'
import {
  getAllPostsWithSlug,
  getPageData,
  getNavigation,
  getFooterNavigation,
} from '@/lib/api'
import Sections from '@/components/Sections'

export default function Page({
  pageData,
  navigationData,
  footerNavigationData,
  preview,
}) {
  const { sections, slug } = pageData
  const router = useRouter()

  if (router.isFallback) {
    return <h1>Loading…</h1>
  }

  if (!slug) {
    return <ErrorPage statusCode={404} />
  }

  const isHomePage = router.asPath === '/'

  const sectionPageData = {
    shortName: pageData.shortName,
  }

  return (
    <Layout
      preview={preview}
      pageData={pageData}
      navigationData={navigationData}
      footerNavigationData={footerNavigationData}
      isHomePage={isHomePage}
    >
      <Sections
        sections={sections}
        preview={preview}
        sectionPageData={sectionPageData}
      />
    </Layout>
  )
}

export async function getStaticProps(context) {
  const { params, locale, locales, defaultLocale, preview = null } = context

  const pageSlug = (!params.slug ? ['/'] : params.slug).join('/')
  const pageData = await getPageData({
    slug: pageSlug,
    locale,
    preview,
  })

  if (!pageData) {
    return { notFound: true }
  }

  const navigationData = await Promise.all(
    locales.map(async (locale) => ({
      [locale]: await getNavigation(locale),
    }))
  )

  const footerNavigationData = await Promise.all(
    locales.map(async (locale) => ({
      [locale]: await getFooterNavigation(locale),
    }))
  )

  return {
    props: {
      pageData,
      navigationData,
      footerNavigationData,
      preview,
    },
    revalidate: 60, // Revalidate every 60 seconds
  }
}

export async function getStaticPaths({ locales }) {
  const allPaths = []

  for (const locale of locales) {
    const allPages = await getAllPostsWithSlug({ locale })

    allPages?.pages.data.forEach((page) => {
      const slugArray = page.attributes.slug
        ? page.attributes.slug.split('/')
        : false

      if (slugArray) {
        allPaths.push({
          params: { slug: slugArray },
          locale: page.attributes.locale,
        })
      }
    })
  }

  return {
    paths: allPaths,
    fallback: 'blocking', // Enable blocking fallback for better UX
  }
}
