import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import { useState, useRef, useEffect } from 'react'
import Image from 'next/image'
import clsx from 'clsx'
import Link from 'next/link'
import { link } from '@/lib/api'
import { useRouter } from 'next/router'

export default function Event({ data }) {
  const className = ''
  const images = data.images.data

  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const containerRef = useRef(null)
  const [containerWidth, setContainerWidth] = useState(0)

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth)
      }
    }

    updateWidth()
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  const containerHeight = containerWidth * (9 / 16) // 16:9 aspect ratio

  const slugify = (str) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '')

  const headingSlug = slugify(data.heading)
  const eventId = `event-${headingSlug}-${
    data.id || Math.random().toString(36).slice(2, 11)
  }`

  const [copied, setCopied] = useState(false)

  const copyToClipboard = () => {
    const url = `${window.location.origin}${window.location.pathname}#${eventId}`
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    })
  }

  const [showCalendarPopup, setShowCalendarPopup] = useState(false)

  const generateEventDetails = () => {
    const { heading, startdate, enddate, text } = data
    const description = text.replace(/<[^>]*>?/gm, '')
    const location = 'Dämmans fyrplats, 3M5G+58, 383 91 Svartö, Sweden'

    const startDate = new Date(startdate)
    const endDate = new Date(enddate)

    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      console.error('Invalid date format:', { startdate, enddate })
      return null
    }

    return { heading, description, location, startDate, endDate }
  }

  const addToGoogleCalendar = () => {
    const event = generateEventDetails()
    if (!event) return

    const url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      event.heading
    )}&dates=${event.startDate
      .toISOString()
      .replace(/-|:|\.\d\d\d/g, '')}/${event.endDate
      .toISOString()
      .replace(/-|:|\.\d\d\d/g, '')}&details=${encodeURIComponent(
      event.description
    )}&location=${encodeURIComponent(event.location)}`
    window.open(url, '_blank')
  }

  const addToAppleCalendar = () => {
    const event = generateEventDetails()
    if (!event) return

    const url = `data:text/calendar;charset=utf8,BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
URL:${document.URL}
DTSTART:${event.startDate.toISOString().replace(/-|:|\.\d\d\d/g, '')}
DTEND:${event.endDate.toISOString().replace(/-|:|\.\d\d\d/g, '')}
SUMMARY:${event.heading}
DESCRIPTION:${event.description}
LOCATION:${event.location}
END:VEVENT
END:VCALENDAR`

    window.open(encodeURI(url))
  }

  const addToOutlookCalendar = () => {
    const event = generateEventDetails()
    if (!event) return

    const url = `https://outlook.live.com/calendar/0/deeplink/compose?subject=${encodeURIComponent(
      event.heading
    )}&startdt=${event.startDate.toISOString()}&enddt=${event.endDate.toISOString()}&body=${encodeURIComponent(
      event.description
    )}&location=${encodeURIComponent(event.location)}`
    window.open(url, '_blank')
  }

  const addToOtherCalendar = () => {
    const event = generateEventDetails()
    if (!event) return

    const url = `data:text/calendar;charset=utf8,${encodeURIComponent(`BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
URL:${document.URL}
DTSTART:${event.startDate.toISOString().replace(/-|:|\.\d\d\d/g, '')}
DTEND:${event.endDate.toISOString().replace(/-|:|\.\d\d\d/g, '')}
SUMMARY:${event.heading}
DESCRIPTION:${event.description}
LOCATION:${event.location}
END:VEVENT
END:VCALENDAR`)}`

    const link = document.createElement('a')
    link.href = url
    link.download = 'event.ics'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const router = useRouter()
  const { locale } = router

  const isSwedish = locale === 'sv'

  return (
    <div
      id={eventId}
      className={clsx('mx-auto mb-24 w-full max-w-3xl px-4 py-8', className)}
    >
      <div ref={containerRef} className="w-full">
        <div className="relative">
          <div
            style={{ height: `${containerHeight}px` }}
            className="relative w-full"
          >
            <div
              ref={sliderRef}
              className="keen-slider absolute left-0 top-0 h-full w-full"
            >
              {images.map((image) => (
                <div key={image.id} className="keen-slider__slide">
                  <Image
                    src={image.attributes.formats.large.url}
                    alt={image.attributes.alternativeText || 'Slide image'}
                    fill
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                    style={{ objectFit: 'cover' }}
                    priority
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="absolute right-0 top-full mt-2 flex flex-col items-end">
            <button
              onClick={copyToClipboard}
              className="mb-0 text-xs text-dammanText underline hover:bg-black hover:bg-opacity-5 sm:mb-[0.15rem] sm:text-sm"
            >
              {copied
                ? isSwedish
                  ? 'Kopierad!'
                  : 'Copied!'
                : isSwedish
                ? 'Kopiera länk'
                : 'Copy link'}
            </button>
            {data.startdate && data.enddate && (
              <button
                onClick={() => setShowCalendarPopup(true)}
                className="text-xs text-dammanText underline hover:bg-black hover:bg-opacity-5 sm:text-sm"
              >
                {isSwedish ? 'Lägg till i kalender' : 'Add to Calendar'}
              </button>
            )}
          </div>
        </div>

        {/* Navigation dots */}
        {loaded && instanceRef.current && (
          <div className="mt-4 flex justify-center">
            {[
              ...Array(
                instanceRef.current.track.details?.slides?.length
              ).keys(),
            ].map((idx) => (
              <button
                key={idx}
                onClick={() => instanceRef.current?.moveToIdx(idx)}
                className={`mx-1 h-2 w-2 rounded-full ${
                  currentSlide === idx ? 'bg-gray-600' : 'bg-gray-300'
                }`}
              ></button>
            ))}
          </div>
        )}
      </div>

      <h2 className="mb-0 mt-8 text-center">{data.heading}</h2>
      {(data.startdate || data.enddate) && (
        <p className="text-bold mt-0 text-center text-md sm:text-lg md:mt-2">
          {data.startdate && (
            <>
              {new Date(data.startdate).getDate()}
              {/* Day */}
              {data.startdate && data.enddate && '—'}
              {data.enddate && new Date(data.enddate).getDate()}
              {/* Day */}{' '}
              {new Date(data.startdate).toLocaleDateString(locale, {
                month: 'long',
              })}
              {/* Month */}
              {', '}
              {new Date(data.startdate).getFullYear()}
              {/* Year */}
            </>
          )}
        </p>
      )}
      <div
        className="mt-4 text-center md:mt-8 [&_*]:!text-dammanText"
        dangerouslySetInnerHTML={{ __html: data.text }}
      />
      <div className={'flex w-full justify-center'}>
        <Link passHref href={'mailto:info@damman.se'} legacyBehavior>
          <button
            href={'mailto:info@damman.se'}
            className="border-1 mt-4 border-[1px] border-solid border-dammanText px-4 py-[0.5rem] font-raleway text-md tracking-normal no-underline transition-all duration-200 ease-in-out hover:bg-dammanBgDark hover:text-dammanBg dark:text-dammanBgDark hover:dark:bg-white sm:mt-4  "
          >
            {isSwedish ? 'Kontakta oss' : 'Contact'}
          </button>
        </Link>
      </div>
      {showCalendarPopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-xs rounded-lg bg-white p-6 shadow-xl">
            <h3 className="mb-4 text-center text-lg font-semibold text-gray-800">
              Choose Calendar
            </h3>
            <button
              onClick={addToGoogleCalendar}
              className="mb-2 w-full bg-gray-100 px-4 py-2 text-gray-800 hover:bg-gray-200"
            >
              Google Calendar
            </button>
            <button
              onClick={addToAppleCalendar}
              className="mb-2 w-full bg-gray-200 px-4 py-2 text-gray-800 hover:bg-gray-300"
            >
              Apple Calendar
            </button>
            <button
              onClick={addToOutlookCalendar}
              className="mb-2 w-full bg-gray-300 px-4 py-2 text-gray-800 hover:bg-gray-400"
            >
              Outlook Calendar
            </button>
            <button
              onClick={addToOtherCalendar}
              className="mb-4 w-full bg-gray-400 px-4 py-2 text-white hover:bg-gray-500"
            >
              Other Calendars
            </button>
            <button
              onClick={() => setShowCalendarPopup(false)}
              className="w-full rounded bg-gray-100 px-4 py-2 text-gray-800 hover:bg-gray-200"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
