import { Container } from '../Container'
import { NpContainer } from '../NPContainer'

const RichText = ({ data }) => {
  return (
    <NpContainer
      innerProps="w-full max-w-screen-text"
      outerProps="w-full items-center mx-0 py-6 md:py-10 lg:py-12 px-6 sm:px-8 bigScreen:py-16"
    >
      <div
        className="flex flex-col items-start justify-start"
        dangerouslySetInnerHTML={{ __html: data.richText }}
      />
    </NpContainer>
  )
}
export default RichText
